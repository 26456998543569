import { SVGProps } from "react";

export default function UiHomeAdvisorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#67747B"
        d="M12.77 9.987l-.31-.32-4.705-4.835L3.689 9.01v4.06c0 .59.476 1.078 1.05 1.078H9.54l3.672 3.774v-3.774h2.05c.573 0 1.048-.489 1.048-1.078V9.987h-3.54z"
      ></path>
      <path
        fill="#F19200"
        d="M13.178 2.495H6.822L.417 9.04h1.82l5.496-5.615 5.495 5.615h6.356l-6.406-6.545z"
      ></path>
    </svg>
  );
}
