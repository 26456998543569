import { useEffect, useState } from 'react';

import { defaultSentryContext } from '@/sentry/context';
import SentryImportPromise from '@/sentry/importPromise';

export default function useSentryInit() {
  const [sentryContextValue, setSentryContextValue] = useState(defaultSentryContext);

  useEffect(function initSentry() {
    SentryImportPromise.then(imp => {
      setSentryContextValue(imp.default);
    });
  }, []);

  return sentryContextValue;
}
