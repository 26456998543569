import { useState, useEffect, useCallback, useMemo } from 'react';

import { useToast } from '@/components/Toast/context';
import LobbyPage from '@/app/pages/LobbyPage/LobbyPage';
import { Permissions } from '@/app/types';
import { useMeetingData } from '@/app/queries/MeetingQuery/context';
import { formatDate } from '@/app/utils/date';
import { EVENTS, gtag } from '@/lib/gtag';


interface ILobbyPageProps {
  onJoin(permissions: Permissions): void;
}

export default function LobbyPageContainer({ onJoin }: ILobbyPageProps) {
  const toast = useToast();
  const [permissions, setPermissions] = useState({ audio: false, video: false });
  const [videoStream, setVideoStream] = useState<MediaStream | undefined>();

  const meetingData = useMeetingData();

  const lobbyPageData = useMemo(() => {
    const customer = meetingData.participants.find((participant) => !participant.is_host);
    const staff = meetingData.participants.find((participant) => participant.is_host);

    return {
      startTime: formatDate(meetingData.meeting_data.appointment_start),
      address: meetingData.meeting_data.house_address,
      customerName: customer?.user_name,
      staffName: staff?.user_name
    }
  }, [meetingData]);

  const requestPermissions = useCallback(async (permissions: Permissions) => {
    const stream = await navigator.mediaDevices.getUserMedia(permissions);

    setPermissions((prevState) => ({
      ...prevState,
      audio: permissions.audio ? Boolean(stream.getAudioTracks().length) : prevState.audio,
      video: permissions.video ? Boolean(stream.getVideoTracks().length) : prevState.video,
    }));

    if (permissions.video) {
      setVideoStream(stream);
    }

    return stream;
  }, [setPermissions]);

  const handleJoinCall = async () => {
    try {
      // the last permissions check before join
      const stream = await navigator.mediaDevices.getUserMedia(
        { video: false, audio: true }
      );
      stream.getTracks().forEach(track => track.stop());
    } catch (error) {
      console.debug(error);
      toast.publish({
        text: 'Please check your microphone availability and refresh the page',
        variant: 'destructive'
      });
      return;
    }

    gtag(EVENTS.JOIN_CHANNEL, permissions);
    videoStream?.getTracks().forEach(track => track.stop());
    onJoin(permissions);
  };

  useEffect(function requestPermissionsOnMount() {
    requestPermissions({ audio: true }).catch(() => {
      toast.publish({
        text: `Please grant microphone permissions and refresh the page`,
        variant: 'destructive',
      });
    });

    requestPermissions({ video: true }).catch(() => {
      toast.publish({
        text: `Please grant camera permissions and refresh the page`,
        variant: 'destructive',
      });
    });
  }, [requestPermissions, toast]);

  return (
    <LobbyPage
      {...lobbyPageData}
      permissions={permissions}
      requestPermissions={requestPermissions}
      videoStream={videoStream}
      onJoin={handleJoinCall}
    />
  );
}
