import { useCallback } from 'react';
import clsx from 'clsx';
import NiceModal from '@ebay/nice-modal-react';

import UiVideoIcon from '@/icons/UiVideoIcon';
import UiMicrophoneIcon from '@/icons/UiMicrophoneIcon';
import UiCloseIcon from '@/icons/UiCloseIcon';
import UiOkIcon from '@/icons/UiOkIcon';

import { modals } from '@/modals';
import Button from '@/components/Button';
import { Permission, RequestPermissions } from '@/app/types';

import './LobbyPage.css';
import { EVENTS, gtag } from '@/lib/gtag';

const DEVICE_NAME: Record<Permission, string> = {
  audio: 'Microphone',
  video: 'Camera',
}

export default function PermissionLine({
  permission, hasPermission, requestPermissions
}: { permission: Permission, hasPermission?: boolean, requestPermissions: RequestPermissions }) {
  const onRequestPermissions = useCallback(() => {
    requestPermissions({ [permission]: true }).catch(() => {
      // missing permissions
      const permissions = { [permission]: true };

      gtag(EVENTS.SHOW_MODAL, permissions);
      NiceModal.show(modals.RESET_PERMISSIONS_MODAL, { permissions });
    });
  }, [requestPermissions, permission]);

  return (
    <div className="self-stretch rounded-xl bg-whitesmoke flex flex-row items-center justify-between p-3 gap-[20px]">
      <div className="flex flex-row items-center justify-start gap-[5px]">
        <div className="w-8 flex flex-row items-center justify-center py-0 px-0.5 box-border relative">
          {permission === 'video' && (
            <UiVideoIcon
              className={clsx(
                "h-6 w-6 relative",
                { "svg-fill-black": hasPermission }
              )}
            />
          )}
          {permission === 'audio' && (
            <UiMicrophoneIcon
              className={clsx(
                "h-6 w-6 relative",
                { "svg-fill-black": hasPermission }
              )}
            />
          )}
          {!hasPermission && (
            <div className="border-solid border-white flex flex-row items-center justify-center rounded-icon-circle bg-orange-base bore h-[12px] w-[12px] !m-[0] absolute top-[11px] left-[17px] z-[1] border">
              <UiCloseIcon />
            </div>
          )}
        </div>
        <div className="relative leading-[20px] inline-block min-w-[119px]">
          {DEVICE_NAME[permission]} is {hasPermission ? "enabled" : "disabled"}
        </div>
      </div>
      {!hasPermission && (
        <Button
          onClick={onRequestPermissions}
          className="text-xs font-button-big"
          variant="primary"
          size="xs"
        >
          Enable
        </Button>
      )}
      {hasPermission && (
        <UiOkIcon />
      )}
    </div >
  );
}
