import { AgoraRTCReactError, IAgoraRTCClient, ILocalTrack, usePublish as useAgoraPublish, useRTCClient } from "agora-rtc-react";
import useAutoUnpublish from "@/app/hooks/useAutoUnpublish";

interface IUsePublishResult {
  error: AgoraRTCReactError | null,
  isLoading?: boolean;
}

// Ensures to unpublish old track in case if we are publishing a new one.
export default function usePublish(
  track: ILocalTrack | null, readyToPublish?: boolean, agoraClient?: IAgoraRTCClient
): IUsePublishResult {
  const rtcClient = useRTCClient(agoraClient);

  const { error, isLoading } = useAgoraPublish([track], readyToPublish, rtcClient);
  useAutoUnpublish(track, rtcClient);

  return { error, isLoading };
}
