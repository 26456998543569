import { SVGProps } from "react";

export default function UiAngiIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      fill="none"
      viewBox="0 0 20 22"
      {...props}
    >
      <path
        fill="#FF6153"
        d="M16.849 0L20 19.558h-3.3l-.175-1.271c-.297-2.269-1.5-4.365-3.483-4.83-.069.801-.454 3.515-.62 4.222v-.006c-.301 1.483-.945 3.103-2.884 3.767-.407.14-.83.207-1.25.207a4.064 4.064 0 01-2.925-1.257c-.865-.903-1.349-2.004-1.363-3.413-.032-3.372 2.76-6.385 6.357-6.862l.105-.011L12.089 0h4.759zM9.926 13.423c-1.47.519-2.8 1.84-2.783 3.525.005.603.185.96.493 1.283.239.249.593.35.879.252.48-.164.704-.735.875-1.692l.536-3.368zm5.657-2.261l-1.057-8.07H14.41l-.946 7.231a9.24 9.24 0 012.12.839z"
      ></path>
    </svg>
  );
}
