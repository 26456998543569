import { useAppContext } from '@/app/AppContext';
import Button from '@/components/Button';

import IconMicrophone from '@/assets/icons/microphone.svg?react';
import { EVENTS, gtag } from '@/lib/gtag';

export default function ButtonMicrophone() {
  const appState = useAppContext();

  const onButtonClick = () => {
    appState.microphone.toggleDevice();

    gtag(EVENTS.TOGGLE_DEVICE, { device: 'microphone' });
  };

  return (
    <Button
      title="Microphone on/off"
      onClick={onButtonClick}
      className="mx-1"
      variant="default"
      size="icon"
      faded={!appState.microphone.isEnabled}>
      <IconMicrophone className="w-6 h-6" />
    </Button>
  );
}
