import { ReactNode, useMemo } from 'react';
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';

enum AgoraLogLevel {
  DEBUG,
  INFO,
  WARNING,
  ERROR,
  NONE,
}
AgoraRTC.setLogLevel(
  import.meta.env.DEV ? AgoraLogLevel.DEBUG : AgoraLogLevel.WARNING,
);

export default function withAgoraClient<T extends object>(
  Component: (props: T) => ReactNode,
) {
  return function ConnectedComponent(props: T) {
    const agoraClient = useMemo(() => (
      AgoraRTC.createClient({
        codec: 'vp8',
        mode: 'rtc',
        role: 'host',
      })
    ), []);

    return (
      <AgoraRTCProvider client={agoraClient}>
        <Component {...props} />
      </AgoraRTCProvider>
    )
  };
}
