import SettingsDialog from '@/app/components/DialogSettings';
import Button from '@/components/Button';

import IconAdjustments from '@/assets/icons/adjustments-vertical.svg?react';
import { EVENTS, gtag } from '@/lib/gtag';

export default function ButtonSettings() {
  const onButtonClick = () => {
    gtag(EVENTS.OPEN_SETTINGS);
  }

  return (
    <SettingsDialog>
      <Button
        title="Settings"
        className="mx-1"
        size="icon"
        onClick={onButtonClick}
      >
        <IconAdjustments className="w-6 h-6" />
      </Button>
    </SettingsDialog>
  );
}
