import { useCallback, useContext } from 'react';

import { SoundEffectsContext } from '@/app/soundEffects/context';

export default function useSoundEffects() {
  const { play } = useContext(SoundEffectsContext);

  // some generic wrapper to play only one sound
  const playJoinSound = useCallback(() => {
    play('/sounds/user_joined.mp3', 1000);
  }, [play]);

  return {
    play,
    playJoinSound,
  }
}
