import UserFormIcon from '@/icons/UiUserForm';

interface NoVideoIndicatorProps {
    isCameraOn: boolean;
}

export default function NoVideoIndicator({ isCameraOn }: NoVideoIndicatorProps) {


  return (
    <>
      {
        !isCameraOn 
        &&
        <div className="noVideoBgColor shadow-[0px_0px_10px_rgba(0,_0,_0,_0.25)] rounded-2xl flex flex-row items-center justify-center w-full h-full">
            <UserFormIcon
                className="h-[50%] w-[50%] relative"
                width={115}
                height={114}
                viewBox="0 0 115 114"
            />
        </div>
      }
    </>
  );
}
