import { NiceModalHandler, useModal as useNiceModal } from '@ebay/nice-modal-react';
import { useCallback } from 'react';

interface IModalHandler {
  modal: NiceModalHandler;
  onOpenChange(opened: boolean): void;
  open: boolean;
}

/**
 * Adapt nice modal functions to radix dialog interface
 */
export function useModal(): IModalHandler {
  const modal = useNiceModal();

  const onOpenChange = useCallback((opened: boolean) => {
    if (!opened) {
      modal.hide();
      modal.remove();
    }
  }, [modal]);

  return {
    modal,
    onOpenChange,
    open: modal.visible,
  };
}
