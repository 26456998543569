import { AgoraRTCReactError, IAgoraRTCClient, useRTCClient } from "agora-rtc-react";
import { useEffect } from "react";

export default function useAutoAdjustStreamQuality(rtcClient?: IAgoraRTCClient) {
  const client = useRTCClient(rtcClient);

  useEffect(() => {
    // allows clients to switch to low quality profile in case if network quality cannot be satisfied
    client.enableDualStream().catch((error: AgoraRTCReactError) => {
      console.warn('Failed to enable dual stream mode', error);
    });
  }, [client]);
}
