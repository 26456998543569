import Microphone from '@/icons/Microphone';
import NoMicrophone from '@/icons/NoMicrophone';

interface MicrophoneIndicatorProps {
  isMicrophoneOn: boolean;
}

export default function MicrophoneIndicator({ isMicrophoneOn  }: MicrophoneIndicatorProps) {
  return (
    <span className="absolute z-10 right-3 top-3 bg-slate-700/50 p-2 text-sm text-white rounded-xl flex items-center">
      {
        isMicrophoneOn
        ? 
          <Microphone className="w-4 h-4" />
        : 
          <NoMicrophone className="w-4 h-4" />
      }
    </span>
  );
}
