import { useCallback, useEffect, useState } from 'react';

import Button from '@/components/Button';
import useScreenShare from '@/app/hooks/useScreenShare';
import { isPermissionDeniedError } from '@/app/utils/errors';

import IconComputerDesktop from '@/assets/icons/computer-desktop.svg?react';
import { EVENTS, gtag } from '@/lib/gtag';


export default function ButtonScreenShare() {
  const [isScreenShareEnabled, setScreenShareEnabled] = useState(false);

  const { error } = useScreenShare(isScreenShareEnabled);

  useEffect(function onError() {
    if (!error) return;

    if (isPermissionDeniedError(error as Error)) {
      setScreenShareEnabled(false);
    }
  }, [error]);


  const handleToggleScreenShare = useCallback(() => {
    setScreenShareEnabled(isScreenShareEnabled => !isScreenShareEnabled);
    gtag(EVENTS.SCREEN_SHARE);
  }, []);

  return (
    <Button
      title="Share screen"
      onClick={handleToggleScreenShare}
      className="mx-1"
      variant="default"
      size="icon"
      faded={!isScreenShareEnabled}>
      <IconComputerDesktop className="w-6 h-6" />
    </Button>
  );
}
