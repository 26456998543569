import { SVGProps } from "react";

interface UiStarIconProps extends SVGProps<SVGSVGElement> {
  selectedStar: boolean;
  hoverStar: boolean;
}

export default function UiStarIcon({ selectedStar, hoverStar, ...props }: UiStarIconProps) {
  return (
    <div className="group">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        fill="none"
        viewBox="0 0 40 40"
        {...props}
      >
        <path
          className={`${(selectedStar ||  hoverStar) ? 'fill-[rgba(240,158,58,1)]' : 'fill-[#E2E2EA]'}`}
          d="M18.834 28.766l-9.402 4.706c-1.216.608-2.823-.369-2.572-1.763l1.798-9.982-7.626-7.078c-1.065-.988-.36-2.668.983-2.854l10.53-1.457 4.7-9.067c.643-1.24 2.546-1.22 3.178 0l4.7 9.067 10.53 1.457c1.382.191 2.015 1.896.983 2.854l-7.626 7.078 1.798 9.982c.257 1.427-1.378 2.36-2.572 1.763l-9.402-4.706z"
        ></path>
      </svg>
    </div>
  );
}
