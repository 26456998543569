import { SVGProps } from "react";

export default function MicrophoneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.0129 0.75H12.0124C9.85172 0.75 8.1001 2.54631 8.1001 4.76217V12.3881C8.1001 14.6039 9.85172 16.4002 12.0124 16.4002H12.0129C14.1736 16.4002 15.9252 14.6039 15.9252 12.3881V4.76217C15.9252 2.54631 14.1736 0.75 12.0129 0.75Z" fill="#A0A0A9"/>
      <path d="M17.8813 7.75C17.998 7.75 18.1133 7.77483 18.2206 7.82293C18.3267 7.87055 18.4226 7.93992 18.5025 8.02654C18.5817 8.11222 18.6441 8.21336 18.6865 8.32421C18.7285 8.434 18.75 8.55138 18.75 8.66988V12.2114C18.75 15.9676 16.0924 19.1122 12.6444 19.4664L12.868 19.439V21.41L15.0945 21.4107C15.2902 21.4107 15.4781 21.4803 15.6295 21.6055L15.7157 21.6871C15.8749 21.8594 15.9634 22.0904 15.9634 22.3303C15.9634 22.5702 15.8749 22.8013 15.7157 22.9736C15.5529 23.1497 15.3292 23.25 15.0945 23.25H8.90548C8.67085 23.25 8.44719 23.1498 8.28425 22.9736C8.12504 22.8013 8.03658 22.5702 8.03658 22.3303C8.03658 22.0905 8.12503 21.8594 8.28425 21.6871C8.44708 21.5109 8.67077 21.4107 8.90548 21.4107L11.131 21.41V19.44C7.86679 18.9832 5.36591 16.0233 5.25392 12.4613L5.25 12.2118V8.66988C5.25 8.55148 5.27158 8.4341 5.31363 8.32424C5.35602 8.2135 5.41847 8.11238 5.49766 8.02669C5.57785 7.93991 5.67369 7.87063 5.77982 7.82305C5.88697 7.77504 6.00228 7.75 6.1189 7.75C6.23553 7.75 6.35084 7.77503 6.45801 7.82306C6.56411 7.87062 6.65993 7.9399 6.74013 8.02667C6.81933 8.11236 6.88177 8.21347 6.92418 8.32424C6.96623 8.4341 6.98781 8.55148 6.98781 8.66988V12.2118C6.98781 15.2207 9.24078 17.661 11.9998 17.661C14.7589 17.661 17.0122 15.2206 17.0122 12.2118V8.67016C17.0121 8.55167 17.0336 8.43416 17.0757 8.32414C17.1181 8.21332 17.1806 8.11213 17.2598 8.0264C17.34 7.93959 17.4359 7.87028 17.5421 7.82274C17.6492 7.77475 17.7646 7.75 17.8813 7.75Z" fill="#A0A0A9"/>
    </svg>
  );
}
