import { useEffect, useRef } from "react";

import UserFormIcon from '@/icons/UiUserForm';


export default function LobbyVideo({ videoStream }: { videoStream?: MediaStream }) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(function assignVideoStreamToVideoElement() {
    if (videoStream && videoRef.current) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  if (!videoStream) {
    return (
      <div className="w-2/3 md:w-full m-auto shadow-[0px_0px_10px_rgba(0,_0,_0,_0.25)] rounded-2xl bg-black-2 flex flex-row items-center justify-center py-[111px] px-[61px]">
        <UserFormIcon
          className="h-[115px] w-[114px] relative"
          width={115}
          height={114}
          viewBox="0 0 115 114"
        />
      </div>
    );
  }

  return (
    <video
      ref={videoRef}
      muted={true}
      autoPlay
      playsInline
      className="w-2/3 md:w-full m-auto shadow-[0px_0px_10px_rgba(0,_0,_0,_0.25)] rounded-2xl"
    />
  );
}
