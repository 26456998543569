import Layout from '@/app/Layout';

import IconHeart from '@/assets/icons/heart.svg?react';

export default function EndCallPage() {
  return (
    <Layout>
      <div className="flex flex-col justify-center items-center" style={{ marginTop: '32vh' }}>
        <IconHeart className="fill-white h-20 w-20 mb-6" />
        <h1 className="text-3xl text-center text-white">Thank you for the call!</h1>
      </div>
    </Layout>
  );
}
