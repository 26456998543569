import { useCallback, useState } from 'react';

import LobbyPage from './pages/LobbyPage';
import MeetingPage from './pages/MeetingPage';
import EndCallPage from './pages/EndCallPage';
import { Permissions } from './types';


enum CurrentAppPage {
  LOBBY,
  MEETING,
  END_CALL,
}

export default function AppContainer() {
  const [appPage, setAppPage] = useState<CurrentAppPage>(CurrentAppPage.LOBBY);
  const [permissions, setPermissions] = useState<Permissions>({ audio: false, video: false });

  const onJoin = useCallback((newPermissions: Permissions) => {
    setAppPage(CurrentAppPage.MEETING);
    setPermissions(newPermissions);
  }, []);

  const onEndCall = useCallback(() => {
    setAppPage(CurrentAppPage.END_CALL);
  }, []);

  if (appPage === CurrentAppPage.LOBBY) {
    return <LobbyPage onJoin={onJoin} />;
  } else if (appPage === CurrentAppPage.MEETING) {
    return <MeetingPage onEndCall={onEndCall} permissions={permissions} />;
  } else if (appPage === CurrentAppPage.END_CALL) {
    return <EndCallPage />;
  }

  const unsupportedPage: never = appPage;
  throw new Error(`We don't support this page yet ${unsupportedPage}`);
}
