import FieldDeviceSelect from '@/app/components/FieldDeviceSelect';
import { useAppContext } from '@/app/AppContext';


export default function FieldSpeakerSelect() {
  const { speaker } = useAppContext();

  return (
    <FieldDeviceSelect
      devices={speaker.devices}
      onValueChange={speaker.changeDevice}
      title="Speaker"
      value={speaker.deviceId} />
  );
}
