import { useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import fetchAnalytics, { AnalyticsData } from '@/app/mutations/AnalyticsMutation/fetchAnalytics';

export default function useAnalyticsMutation() {
  const { hash } = useParams<{ hash: string }>();

  return useMutation({
    mutationFn(data: AnalyticsData) {
      return fetchAnalytics({ meeting_hash: hash!, metrics: data })
    },
  });
}
