import { SVGProps } from "react";

export default function UiOkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="#54A788" />
      <path d="M16.4271 8.20626C16.8614 8.51648 16.962 9.12003 16.6518 9.55433L11.8199 16.319C11.6547 16.5501 11.3963 16.6969 11.1132 16.7204C10.8301 16.7438 10.551 16.6415 10.3502 16.4406L7.45101 13.5415C7.07362 13.1641 7.07362 12.5522 7.45101 12.1748C7.82841 11.7974 8.44029 11.7974 8.81768 12.1748L10.9102 14.2673L15.079 8.43094C15.3892 7.99664 15.9928 7.89605 16.4271 8.20626Z" fill="white" />
    </svg>
  );
}
