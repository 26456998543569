import AgoraVirtualBackgroundExtension from 'agora-extension-virtual-background';
import type { IVirtualBackgroundProcessor } from 'agora-extension-virtual-background';
import type { ICameraVideoTrack } from 'agora-rtc-react';

import BaseExtension from '@/app/extensions/BaseExtension';

import wasm from '@/assets/wasms/agora-wasm.wasm?url';
import videoBackgroundImage from '@/assets/background.webp';

export default class VirtualBackgroundExtension extends BaseExtension<IVirtualBackgroundProcessor, ICameraVideoTrack> {
  private _init: Promise<void>;

  constructor() {
    super(new AgoraVirtualBackgroundExtension())

    this._init = this.processor.init(wasm).then(() => {
      this._loadBackgroundImage(this.processor);
    });
  }

  private _loadBackgroundImage(processor: IVirtualBackgroundProcessor) {
    const image = new Image();
    image.onload = () => {
      processor.setOptions({ type: 'img', source: image });
    };
    image.src = videoBackgroundImage;
  }

  attach(localCameraTrack: ICameraVideoTrack) {
    this._init.then(() => {
      super.attach(localCameraTrack);
    });
  }

  detach(localCameraTrack: ICameraVideoTrack) {
    this._init.then(() => {
      super.detach(localCameraTrack);
    });
  }
}
