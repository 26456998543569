import { createContext } from 'react';

import type { AnalyticsData } from '@/app/mutations/AnalyticsMutation/fetchAnalytics';

export type AnalyticsGetData = (data: AnalyticsData) => AnalyticsData;

interface ISoundEffectsContext {
  play(soundUrl: string, debounceMs: number): void;
}

export const SoundEffectsContext = createContext<ISoundEffectsContext>(null!);
