import { useEffect } from 'react';

import { useJoin } from 'agora-rtc-react';

import { AppContext, usePrepareAppState } from '@/app/AppContext';
import { APP_ID } from '@/app/config';
import { useAutoRecording } from '@/app/mutations/RecordingMutation/context';
import { useJoinData, useUserData } from '@/app/queries/MeetingQuery/context';
import useSoundEffects from '@/app/hooks/useSoundEffects';
import MeetingView from './MeetingView';
import { Permissions } from '../../types';


interface IMeetingPageProps {
  onEndCall(): void;
  permissions: Permissions
}

export default function MeetingPage({ onEndCall, permissions }: IMeetingPageProps) {
  const appState = usePrepareAppState(permissions);
  const { playJoinSound } = useSoundEffects();

  const userData = useUserData();
  const joinData = useJoinData(APP_ID);

  useJoin(joinData, true);

  useAutoRecording(
    { joined: true }, userData.isHost && import.meta.env.VITE_ENABLE_RECORDING
  );

  useEffect(() => {
    playJoinSound();
  }, [playJoinSound]);

  return (
    <AppContext.Provider value={appState}>
      <MeetingView onEndCall={onEndCall} />
    </AppContext.Provider>
  );
}
