import { createContext, useContext, useMemo } from 'react';

export interface IParticipant {
  is_host: boolean;
  user_id: number;
  screen_share_id: number;
  user_name: string;
}

export interface MeetingData {
  token: string;
  screen_share_token: string;
  meeting_data: {
    channel_name: string;
    house_address: string;
    appointment_start: string;
    is_host: boolean;
    screen_share_id: number;
    user_id: number;
    user_name: string;
    meeting_urn: string;
    review_urls: string[];
  },
  participants: IParticipant[];
}

export const MeetingDataContext = createContext<MeetingData>(null!);

export function useMeetingData() {
  return useContext(MeetingDataContext);
}

export function useJoinData(appid: string) {
  const data = useMeetingData();

  return useMemo(() => ({
    appid,
    token: data.token,
    channel: data.meeting_data.channel_name,
    uid: data.meeting_data.user_id,
  }), [appid, data]);
}

export function useShareData(appid: string) {
  const data = useMeetingData();

  return useMemo(() => ({
    appid,
    token: data.screen_share_token,
    channel: data.meeting_data.channel_name,
    uid: data.meeting_data.screen_share_id,
  }), [appid, data]);
}

export function useUserData() {
  const data = useMeetingData();

  return useMemo(() => ({
    uid: data.meeting_data.user_id,
    name: data.meeting_data.user_name,
    address: data.meeting_data.house_address,
    isClient: !data.meeting_data.is_host,
    isHost: data.meeting_data.is_host,
  }), [data]);
}

/**
 * Retrieves the meeting participant data for a given user ID.
 * Current user and screensharing users are also considered as participants
 * @param userId
 */
export function useParticipantData(userId: number) {
  const { participants } = useMeetingData();

  return useMemo(() => {
    const requestedParticipant = participants.find(user => (
      user.user_id === userId || user.screen_share_id === userId
    ));

    if (requestedParticipant) {
      return {
        uid: requestedParticipant.user_id,
        name: requestedParticipant.user_name,
        isClient: !requestedParticipant.is_host,
        isHost: requestedParticipant.is_host,
        isScreenShare: requestedParticipant.screen_share_id === userId,
      };
    }
  }, [userId, participants]);
}
