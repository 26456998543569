import * as Dialog from '@radix-ui/react-dialog';
import NiceModal from '@ebay/nice-modal-react';

import { Permissions } from '@/app/types';
import { useModal } from '@/modals/hooks';
import Button from '@/components/Button';

import IconCross from '@/assets/icons/cross.svg?react';
import IconWarning from '@/assets/icons/exclamation-triangle.svg?react';
import IconSettings from '@/assets/icons/settings-icon.svg?react';


const ResetPermissionsModal = NiceModal.create(({ permissions }: { permissions: Permissions }) => {
  const { onOpenChange, open } = useModal();

  const getPermissionsLabel = (permissions: Permissions): string => {
    const { audio, video } = permissions;
    return [audio && 'microphone', video && 'camera'].filter(Boolean).join(' and ');
  };

  return (
    <Dialog.Root onOpenChange={onOpenChange} open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className=" bg-black bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog.Title className="m-0 relative leading-[30px] font-bold font-inherit text-3xl font-heading">
            <IconWarning fill="orange" className="w-[25px] h-[25px] mr-1 inline-block" />
            Grant permissions to continue
          </Dialog.Title>
          <Dialog.Description className="text-mauve11 mt-3 mb-5 text-[15px] leading-normal">
            <ol className='list-decimal list-inside'>
              <li className='mb-1'>
                Click the <IconSettings className='inline-block' /> in your browser's address bar
              </li>
              <li className='mb-1'>
                Clear {getPermissionsLabel(permissions)} permission
              </li>
              <li className='mb-1'>
                Refresh the page
              </li>
            </ol>
          </Dialog.Description>
          <div className="mt-[25px] flex justify-end">
            <Dialog.Close asChild>
              <Button size="full" variant="primary">
                Ok
              </Button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <IconCross />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
});

export default ResetPermissionsModal;
