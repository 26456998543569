import { forwardRef } from 'react';

import * as ReactSelect from '@radix-ui/react-select';
import type { SelectItemProps } from '@radix-ui/react-select';

import { cn } from '@/lib/utils';

import IconCheck from '@/assets/icons/check.svg?react';

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(({ children, className, ...props }, forwardedRef) => {
  return (
    <ReactSelect.Item
      className={cn(
        'text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1',
        className
      )}
      {...props}
      ref={forwardedRef}>
        <ReactSelect.ItemText>{children}</ReactSelect.ItemText>
        <ReactSelect.ItemIndicator className="absolute left-0 w-[20px] inline-flex items-center justify-center">
          <IconCheck />
        </ReactSelect.ItemIndicator>
    </ReactSelect.Item>
  );
});

export default SelectItem;
