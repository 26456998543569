import { useMutation } from '@tanstack/react-query';

import sendFeedback, {sendFeedbackParams} from '@/app/mutations/FeedbackMutation/sendFeedback';


export default function useFeedbackMutation() {

  return useMutation({
    mutationFn(data: sendFeedbackParams) {
      return sendFeedback(data);
    },
  });
}
