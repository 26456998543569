import { DefaultError as BaseDefaultError, ErrorProps } from '@/components/Error';
import Button from '@/components/Button';

interface DefaultError extends ErrorProps {
  onClick?(): void;
}

export function DefaultError({ onClick, ...errorProps }: DefaultError) {
  return (
    <BaseDefaultError {...errorProps}>
      {Boolean(onClick) &&
        <Button onClick={onClick}>
          Retry
        </Button>
      }
    </BaseDefaultError>
  );
}
