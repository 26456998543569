import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import useRateLimitedMutation from '@/app/mutations/RecordingMutation/useRateLimitedMutation';
import { RecordingContext, Recording } from '@/app/mutations/RecordingMutation/context';
import fetchRecording, { FetchRecordingParams } from '@/app/mutations/RecordingMutation/fetchRecording';
import { isRateLimitError } from '@/app/utils/errors';
import { useToast } from '@/components/Toast/context';
import { useSentry } from '@/sentry/context';

const ONE_MINUTE_IN_MS = 60 * 1000;

export default function withRecordingMutation<T extends object>(
  Component: (props: T) => ReactNode,
) {
  return function ConnectedComponent(props: T) {
    const { hash } = useParams<{ hash: string }>();

    const Sentry = useSentry();

    const toast = useToast();

    const [isRecording, setIsRecording] = useState(false);

    const { error, mutate, status, variables } = useRateLimitedMutation({
      async mutationFn(params: Omit<FetchRecordingParams, 'meeting_id'>) {
        await fetchRecording({ ...params, meeting_id: hash! })
      },
      onError(error) {
        Sentry.captureException(error);
      },
      retry: false,
    }, ONE_MINUTE_IN_MS);

    const recording = useMemo<Recording>(() => ({
      isRecording,
      start: () => mutate({ action: 'start' }),
      stop: () => mutate({ action: 'stop' }),
    }), [isRecording, mutate]);

    useEffect(function onMutationStatusChange() {
      switch (status) {
        case 'idle':
        case 'pending':
          break;
        case 'success':
          setIsRecording(true);
          break;
        case 'error':
          if (isRateLimitError(error)) {
            console.warn(error.message);
          } else {
            toast.publish({ text: `Recording ${variables.action} failed: ${error.message}`, variant: 'destructive' });
          }
          break;
        default: {
          const neverType: never = status;
          throw new Error(`Unhandled status "${neverType}" in withRecordingMutation`);
        }
      }
    }, [error, status, toast, variables?.action]);

    return (
      <RecordingContext.Provider value={recording}>
        <Component {...props} />
      </RecordingContext.Provider>
    );
  };
}
