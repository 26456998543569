import { useEffect } from 'react';

function Main() {
  useEffect(function disallowIndexEnter() {
    // Main path cannot be used directly
    location.href = 'https://ergeon.com';
  }, []);

  return null;
}

export default Main;
