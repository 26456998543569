import { useCallback, useEffect, useMemo, useState } from 'react';

import AgoraRTC from 'agora-rtc-sdk-ng';

import { useAppContext } from '@/app/AppContext';
import Button from '@/components/Button';

import IconCamera from '@/assets/icons/camera.svg?react';
import { EVENTS, gtag } from '@/lib/gtag';

export default function ButtonSwitchCamera() {
  const appState = useAppContext();

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);

  const facingMode = useMemo(() => {
    const trackSettings = appState.camera.track?.getMediaStreamTrack().getSettings();
    return (trackSettings?.facingMode || 'user') as VideoFacingModeEnum;
  }, [appState.camera]);

  const toggleFacingMode = useCallback(() => {
    const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
    appState.camera.changeDevice({ facingMode: newFacingMode });

    gtag(EVENTS.CHANGE_DEVICE, { facingMode: newFacingMode });
  }, [appState.camera, facingMode]);

  useEffect(function toggleMainVideoMirrored() {
    appState.setIsMainVideoMirrored(facingMode === 'user');
  }, [appState, facingMode]);

  useEffect(function initCameras() {
    if (appState.camera.isPermissionGranted) {
      AgoraRTC.getCameras().then(setCameras, () => console.debug('Unable to access cameras'));
    }
  }, [appState.camera]);

  return (
    <Button
      disabled={cameras.length <= 1}
      title="Switch camera"
      onClick={toggleFacingMode}
      className="mx-1"
      variant="info"
      size="icon">
      <IconCamera className="w-6 h-6" />
    </Button>
  );
}
