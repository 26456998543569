import { IAgoraRTCClient, ILocalTrack, useRTCClient } from "agora-rtc-react";
import { useCallback, useEffect, useRef } from "react";

function isSameTrack(trackA: ILocalTrack, trackB: ILocalTrack) {
  // new track is being created, but we are still publishing old track
  return trackA.getTrackId() == trackB.getTrackId();
}

// Ensure a track is safely unpublished when publishing a new track.
export default function useAutoUnpublish(track: ILocalTrack | null, client?: IAgoraRTCClient) {
  const savedTrack = useRef<ILocalTrack | null>(track);
  const agoraClient = useRTCClient(client);

  const unpublishTrack = useCallback(async (oldTrack: ILocalTrack) => {
    try {
      oldTrack.stop();
      await agoraClient.unpublish([oldTrack]);
    } catch (error) {
      console.error(
        `Failed to unpublish old track ${oldTrack.trackMediaType}:${oldTrack.getTrackId()}: ${error}`
      );
    }
  }, [agoraClient]);

  useEffect(function onTrackChange() {
    const oldTrack = savedTrack.current;

    if (!oldTrack) {
      savedTrack.current = track;
      return;
    }

    if (track && oldTrack && !isSameTrack(track, oldTrack)) {
      unpublishTrack(oldTrack!);
      savedTrack.current = track;
    }
  }, [track, agoraClient, unpublishTrack]);
}
