import NiceModal from '@ebay/nice-modal-react';

import ResetPermissionsModal from '@/modals/ResetPermissionsModal';
import { modals } from '@/modals';

NiceModal.register(
  modals.RESET_PERMISSIONS_MODAL,
  ResetPermissionsModal,
  { keepMounted: false }
);

export default NiceModal;
