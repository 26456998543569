import { useRef } from 'react';
import { DefaultError, MutationFunction, useMutation, UseMutationOptions } from '@tanstack/react-query';

export default function useRateLimitedMutation<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
>({ mutationFn, ...options }: UseMutationOptions<TData, TError, TVariables, TContext>, ms: number) {
  const lastCalledRef = useRef<number>();

  return useMutation<TData, TError, TVariables, TContext>({
    ...options,
    mutationFn: async (params) => {
      const now = Date.now();

      if (lastCalledRef.current) {
        const timeSinceLastCall = now - lastCalledRef.current;
        if (timeSinceLastCall < ms) {
          throw new Error('Rate limit exceeded');
        }
      }

      lastCalledRef.current = now; // Update the last called time

      return (mutationFn as MutationFunction<TData, TVariables>)(params);
    }
  });
}
