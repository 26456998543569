import { SVGProps } from "react";

export default function UiVideoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5 4.5C3.34315 4.5 2 5.84315 2 7.5V16.5C2 18.1569 3.34315 19.5 5 19.5H13.25C14.9069 19.5 16.25 18.1569 16.25 16.5V7.5C16.25 5.84315 14.9069 4.5 13.25 4.5H5Z" fill="#A0A0A9" />
      <path d="M20.4393 18.75L17.75 16.0606V7.93931L20.4393 5.24996C21.3843 4.30501 23 4.97427 23 6.31063V17.6893C23 19.0257 21.3843 19.6949 20.4393 18.75Z" fill="#A0A0A9" />
    </svg>
  );
}
