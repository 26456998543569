import { API_BASE } from '@/app/config';

interface IParticipantResponse {
  is_host: boolean;
  screen_share_id: string;
  user_id: string;
  user_name: string;
}

export interface MeetingDataResponse {
  token: string;
  screen_share_token: string;
  meeting_data: {
    channel_name: string;
    house_address: string;
    is_host: boolean;
    appointment_start: string;
    screen_share_id: string;
    user_id: string;
    user_name: string;
    meeting_urn: string;
    review_urls: string[];
  };
  participants: IParticipantResponse[];
}

export default async function fetchMeeting(hash: string) {
  const response = await fetch(`${API_BASE}/meeting/${hash}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const data = await response.json() as MeetingDataResponse;

  const participants = data.participants.map((participant) => ({
    ...participant,
    user_id: parseInt(participant.user_id, 10),
    screen_share_id: parseInt(participant.screen_share_id, 10),
  }));

  return {
    ...data,
    meeting_data: {
      ...data.meeting_data,
      // Recording will not be properly mapped if the next values are of type string.
      user_id: parseInt(data.meeting_data.user_id, 10),
      screen_share_id: parseInt(data.meeting_data.screen_share_id, 10),
    },
    participants
  };
}
