import { defaultSentryContext, SentryContextType } from '@/sentry/context';

type SentryImportPromise = Promise<{ default: SentryContextType }>;

let importPromise: SentryImportPromise;

if (process.env.NODE_ENV === 'production') {
  importPromise = import('@/sentry/init');
} else {
  importPromise = Promise.resolve({ default: defaultSentryContext });
}

export default importPromise;
