import { ReactNode, useEffect } from 'react';

import { useSentry } from '@/sentry/context';

export interface ErrorProps {
  status?: string;
  title?: string;
  description?: string;
  error?: Error;
  children?: ReactNode;
}

export default function BaseError({ status, title, description, error, children }: ErrorProps) {
  const Sentry = useSentry();

  useEffect(function captureExceptionOnce() {
    if (error) Sentry.captureException(error);
  }, [error, Sentry]);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="mb-6 text-6xl font-bold text-red-600">{status}</h1>
        <p className="mb-6 text-2xl font-semibold text-gray-800">{title}</p>
        <p className="mb-1 text-lg text-gray-600">{description}</p>
        {error?.message &&
          <p className="text-sm text-gray-400">Original error message: {error?.message}</p>
        }
        <div className="mt-10">{children}</div>
      </div>
    </div>
  );
}

export function DefaultError(props: ErrorProps) {
  return (
    <BaseError
      status="Oops!"
      title="Something Went Wrong"
      description="We are sorry for the inconvenience. Please try again later."
      {...props} />
  );
}

export function Error404(props: ErrorProps) {
  return (
    <BaseError
      status="404"
      title="Page Not Found"
      description="The page you're looking for doesn't exist or has been moved."
      {...props} />
  );
}

export function Error500(props: ErrorProps) {
  return (
    <BaseError
      status="500"
      title="Internal Server Error"
      description="Oops! Something went wrong on our end. We are notified. Please try refreshing the page, or come back later."
      {...props} />
  );
}
