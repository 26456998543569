import {
  HTMLProps,
  useCallback,
} from 'react';

import {
  LocalVideoTrack,
  RemoteUser,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
} from 'agora-rtc-react';

import MicrophoneIndicator from '@/app/components/MicrophoneIndicator';
import NoVideoIndicator from '@/app/components/NoVideoIndicator';
import VideoDisplayTitle from '@/app/components/VideoDisplayTitle';
import { useParticipantData } from '@/app/queries/MeetingQuery/context';
import { useAppContext } from '../AppContext';

export interface VideoTrackInfo {
  uid: number,
  source: ICameraVideoTrack | IAgoraRTCRemoteUser,
  sourceType: 'local' | 'remote',
  isEnlarged: boolean,
  hasAudio: boolean,
  hasVideo: boolean,
}

interface VideoDisplayProps extends Omit<HTMLProps<HTMLDivElement>, 'onClick'> {
  videoTrack: VideoTrackInfo;
  onClick(videoTrack: VideoTrackInfo): void;
}

export default function VideoDisplay({ onClick, videoTrack, ...divProps }: VideoDisplayProps) {
  const { speaker } = useAppContext();
  const participantData = useParticipantData(videoTrack.uid);

  const onClickTrack = useCallback(() => {
    onClick(videoTrack);
  }, [onClick, videoTrack]);

  switch (videoTrack.sourceType) {
    case 'local':
      return (
        <>
          <VideoDisplayTitle title={participantData?.name} />
          <LocalVideoTrack
            {...divProps}
            track={videoTrack.source as ICameraVideoTrack}
            play={videoTrack.hasVideo}
            onClick={onClickTrack}
          >
            <MicrophoneIndicator isMicrophoneOn={videoTrack.hasAudio} />
            <NoVideoIndicator isCameraOn={videoTrack.hasVideo} />
          </LocalVideoTrack>
        </>

      )
    case 'remote':
      return (
        <>
          {!participantData?.isScreenShare && (
            <VideoDisplayTitle title={participantData?.name} />
          )}
          <RemoteUser
            {...divProps}
            playbackDeviceId={speaker.deviceId}
            user={videoTrack.source as IAgoraRTCRemoteUser}
            playVideo={true}
            playAudio={true}
            onClick={onClickTrack}
          >
            <MicrophoneIndicator isMicrophoneOn={videoTrack.hasAudio} />
            <NoVideoIndicator isCameraOn={videoTrack.hasVideo} />
          </RemoteUser>
        </>
      );
    default: {
      const neverType: never = videoTrack.sourceType;
      throw new TypeError(`Unhandled video type: ${neverType}`);
    }
  }
}
