import { useCallback, useEffect, useRef } from 'react';

type UseIntervalCallback = () => void;

export default function useInterval(callback: UseIntervalCallback, interval = 0) {
  const callbackRef = useRef<UseIntervalCallback>(callback);
  const intervalRef = useRef<number>(interval);

  const previousRef = useRef<number>();
  const requestRef = useRef<number>();

  const tick = useCallback((now: number) => {
    if (!previousRef.current) {
      previousRef.current = now;
    }
    if (previousRef.current && (now - previousRef.current) >= intervalRef.current) {
      callbackRef.current();
      previousRef.current = now;
    }
    requestRef.current = requestAnimationFrame(tick);
  }, []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    intervalRef.current = interval;
  }, [interval]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(tick);

    return () => {
      cancelAnimationFrame(requestRef.current!);
    };
  }, [tick]);
}
