import { useEffect } from 'react';

import { useNetworkQuality, useRTCClient } from 'agora-rtc-react';

import { useAnalytics } from '@/app/analytics/context';

export default function useStatsAnalytics() {
  const { track } = useAnalytics<{
    codec: string;
    detectBadNetworkQuality: boolean,
    detectBestNetworkQuality: boolean,
    maxRecvBitrate: number,
    minRecvBitrate: number,
    maxSendResolutionWidth: number,
    minSendResolutionWidth: number,
    maxSendResolutionHeight: number,
    minSendResolutionHeight: number,
    maxTotalSendBitrate: number,
    minTotalSendBitrate: number,
    outgoingAvailableBandwidth: number,
    participantJoin: boolean,
    rttMs: number,
    sendFrameRate: number,
    totalFreezeTime: number,
  }>();

  const agoraClient = useRTCClient();

  const { downlinkNetworkQuality, uplinkNetworkQuality } = useNetworkQuality(); // every 2s if changed

  useEffect(function trackNetworkAndSTats() {
    const agoraStats = agoraClient.getRTCStats();
    const localStats = agoraClient.getLocalVideoStats();

    track(prevData => ({
      codec: localStats.codecType!,
      detectBadNetworkQuality:
        prevData.detectBadNetworkQuality || (downlinkNetworkQuality > 3 && uplinkNetworkQuality > 3),
      detectBestNetworkQuality:
        prevData.detectBestNetworkQuality || (downlinkNetworkQuality === 1 && uplinkNetworkQuality === 1),
      maxRecvBitrate: Math.max(prevData.maxRecvBitrate ?? 0, agoraStats.RecvBitrate),
      minRecvBitrate: Math.min(prevData.minRecvBitrate ?? 0, agoraStats.RecvBitrate),
      maxSendResolutionWidth: Math.max(prevData.maxSendResolutionWidth ?? 0, localStats.sendResolutionWidth),
      minSendResolutionWidth: Math.min(prevData.minSendResolutionWidth ?? 0, localStats.sendResolutionWidth),
      maxSendResolutionHeight: Math.max(prevData.maxSendResolutionHeight ?? 0, localStats.sendResolutionHeight),
      minSendResolutionHeight: Math.min(prevData.minSendResolutionHeight ?? 0, localStats.sendResolutionHeight),
      maxTotalSendBitrate: Math.max(prevData.maxTotalSendBitrate ?? 0, agoraStats.SendBitrate),
      minTotalSendBitrate: Math.min(prevData.minTotalSendBitrate ?? 0, agoraStats.SendBitrate),
      outgoingAvailableBandwidth: agoraStats.OutgoingAvailableBandwidth,
      participantJoin: prevData.participantJoin || (agoraStats.UserCount > 1),
      rttMs: agoraStats.RTT,
      sendFrameRate: localStats.sendFrameRate!,
      totalFreezeTime: localStats.totalFreezeTime,
    }));
  }, [agoraClient, downlinkNetworkQuality, track, uplinkNetworkQuality]);
}
