import { ReactNode } from 'react';

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="container mx-auto bg-gray-900">
      <div className="flex flex-col min-h-svh pt-10">
        {children}
      </div>
    </div>
  );
}
