import clsx from "clsx";

import Logo from "@/components/Logo";
import Button from "@/components/Button";

import { Permissions, RequestPermissions } from "@/app/types";
import PermissionLine from "@/app/pages/LobbyPage/PermissionLine";
import LobbyVideo from "@/app/pages/LobbyPage/LobbyVideo";


interface ILobbyPageProps {
  startTime: string;
  address: string;
  customerName?: string;
  staffName?: string;
  permissions: Permissions;
  requestPermissions: RequestPermissions;
  videoStream?: MediaStream;
  onJoin(): void;
}


export default function LobbyPage(
  {
    startTime,
    address,
    customerName,
    staffName,
    permissions,
    requestPermissions,
    videoStream,
    onJoin,
  }: ILobbyPageProps
) {
  return (
    <div className="w-full min-h-screen h-max relative bg-base-white-100 flex flex-col items-center justify-start pt-6 px-5 box-border gap-[36px] leading-[normal] tracking-[normal] text-left text-darkgray font-default">
      <div className="flex flex-row items-center justify-start">
        <Logo />
      </div>
      <section className="grid grid-cols-1 md:grid-cols-12 gap-[42px] lg:gap-[60px]">
        <div className="hidden md:flex flex-col md:col-span-7 lg:col-span-8">
          <div className="flex flex-1">
            <LobbyVideo videoStream={videoStream} />
          </div>
          <div className="text-center bg-base-white-100 pt-3 pb-6 hidden md:max-lg:block">
            <Button
              variant="primary"
              onClick={onJoin}
              size="full"
              className={clsx(
                "text-base",
                { "opacity-[0.3]": !permissions.audio }
              )}
            >
              Join call
            </Button>
          </div>
        </div>
        <div className="col-span-1 md:col-span-5 lg:col-span-4">
          <div className="flex h-full w-full flex-col xs:items-center justify-start">
            <section className="flex-1 text-left text-black">
              <div className="flex flex-col items-center md:items-start justify-start">
                <div className="flex flex-col items-center md:items-start justify-start">
                  <h3 className="m-0 relative leading-[30px] font-bold font-inherit text-3xl font-heading">
                    Live Video Estimate
                  </h3>
                  <p className="mt-[6px]">{startTime}</p>
                </div>
                <div className="md:hidden mt-6">
                  <LobbyVideo videoStream={videoStream} />
                </div>

                <div className="self-stretch text-center bg-base-white-100 mt-6 md:pb-0 block md:max-lg:hidden">
                  <Button
                    variant="primary"
                    onClick={onJoin}
                    size="full"
                    className={clsx(
                      "text-base",
                      { "opacity-[0.3]": !permissions.audio }
                    )}
                  >
                    Join call
                  </Button>
                </div>

                <div className="self-stretch mt-6 lg:mt-9">
                  <div className="mb-3">
                    <h6 className="font-bold text-base font-heading">
                      Check permissions
                    </h6>
                  </div>
                  <div className="flex flex-col items-start justify-start mt-[14px] gap-3 font-default-regular">
                    <PermissionLine
                      permission={"video"}
                      hasPermission={permissions.video}
                      requestPermissions={requestPermissions}
                    />
                    <PermissionLine
                      permission={"audio"}
                      hasPermission={permissions.audio}
                      requestPermissions={requestPermissions}
                    />
                  </div>
                </div>

                <div className="self-stretch mt-6 lg:mt-9">
                  <div className="mb-3">
                    <h6 className="font-bold text-base font-heading">
                      Appointment details
                    </h6>
                  </div>
                  <div>
                    <table className="text-sm font-normal">
                      <tbody>
                        <tr>
                          <td className="text-gray-2 pr-2 pb-3">Address</td>
                          <td className="pb-3">{address}</td>
                        </tr>
                        <tr>
                          <td className="text-gray-2 pr-2 pb-3">Customer</td>
                          <td className="pb-3">{customerName}</td>
                        </tr>
                        <tr>
                          <td className="text-gray-2 pr-2 pb-3">Sales rep</td>
                          <td className="pb-3">{staffName}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
            </section>
          </div>
        </div>
      </section>
    </div >
  );
}
