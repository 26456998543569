import { useAnalytics } from '@/app/analytics/context';
import useAnalyticsMutation from '@/app/mutations/AnalyticsMutation/useAnalyticsMutation';
import useInterval from '@/app/hooks/useInterval';
import { useCallback } from 'react';

const SYNC_EVERY_MS = 15 * 1000;

export default function useAnalyticsSync() {
  const { data } = useAnalytics();

  const { mutate } = useAnalyticsMutation();

  const sendDataToServer = useCallback(() => {
    mutate(data);
  }, [data, mutate]);

  useInterval(sendDataToServer, SYNC_EVERY_MS);
}
