import { createContext, useContext } from 'react';

import type { ICameraVideoTrack } from 'agora-rtc-react';

import type { IBaseExtension } from '@/app/extensions/BaseExtension';
import { useExtension } from '@/app/extensions/useExtension';

export const VirtualBackgroundExtensionContext = createContext<IBaseExtension<ICameraVideoTrack> | null>(null);

export function useVirtualBackgroundExtension(
  { track }: { track: ICameraVideoTrack | null },
  isEnabled: boolean,
) {
  const extension = useContext(VirtualBackgroundExtensionContext);

  return useExtension(extension, { track }, isEnabled);
}
