import {
  AgoraRTCReactError,
  ILocalTrack,
} from "agora-rtc-react";

import { useEffect, useState } from "react";

import { isTrackSame } from '@/app/utils/track';

type TrackOptions = {
  isLoading: boolean,
  error: AgoraRTCReactError | null,
}

// Ensures that app uses only one track (camera, mic) instance to prevent
// issues happening on reconnect: double track publishing, stacked echo...
export function useSingleTrackInstance<T extends ILocalTrack>(
  track: T | null, options: TrackOptions
): {
  localTrack: T | null;
  isLoading: boolean;
  error: AgoraRTCReactError | null;
} {
  const [savedTrack, setSavedTrack] = useState<T | null>(track);
  // we are keeping isLoading and error of the first track instance
  // to prevent unnecessary rerenders and going into loading state
  // because agora recreates them on reconnect.
  const [trackOptions, setTrackOptions] = useState<TrackOptions>(options || {});

  useEffect(() => {
    if (!savedTrack && track) {
      setSavedTrack(track);
      setTrackOptions(options);
      return;
    }

    if (savedTrack && track && !isTrackSame(savedTrack, track)) {
      track.close();
    }
  }, [track, savedTrack, options]);

  return {
    localTrack: savedTrack,
    isLoading: trackOptions.isLoading || false,
    error: trackOptions.error
  }
}
