import { Fragment, useCallback, useEffect, useState } from 'react';

import { IAgoraRTCRemoteUser, ICameraVideoTrack } from 'agora-rtc-react';

import { useAppContext } from '@/app/AppContext';
import VideoDisplay, { VideoTrackInfo } from '@/app/components/VideoDisplay';
import { useRecording } from '@/app/mutations/RecordingMutation/context';
import { useUserData } from '@/app/queries/MeetingQuery/context';

import { cn } from '@/lib/utils';
import { EVENTS, gtag } from '@/lib/gtag';

interface VideoTracksDisplayProps {
  localCameraTrack: ICameraVideoTrack;
  remoteUsers: IAgoraRTCRemoteUser[];
  showVideoTracks: boolean;
}

export default function VideoTracksDisplay({ localCameraTrack, remoteUsers, showVideoTracks }: VideoTracksDisplayProps) {
  const { isMainVideoMirrored, camera, microphone } = useAppContext();

  const { isRecording } = useRecording();
  const userData = useUserData();

  const [videoTracks, setVideoTracks] = useState<VideoTrackInfo[]>([]);

  const handleVideoClick = useCallback((clickedVideoTrack: VideoTrackInfo) => {
    gtag(EVENTS.FOCUS_STREAM);

    setVideoTracks(videoTracks => videoTracks.map(video => ({
      ...video,
      isEnlarged: video.source === clickedVideoTrack.source
    })));
  }, []);

  useEffect(function initTracks() {
    setVideoTracks(prevTracks => {
      return [
        {
          uid: userData.uid,
          source: localCameraTrack,
          sourceType: 'local',
          isEnlarged: prevTracks.find(track => track.uid === userData.uid)?.isEnlarged ?? true,
          hasAudio: microphone.isEnabled,
          hasVideo: camera.isEnabled,
        },
        ...remoteUsers.map(user => {
          const existingTrack = prevTracks.find(track => track.uid === user.uid);

          return {
            uid: user.uid as number,
            source: user,
            sourceType: 'remote' as VideoTrackInfo['sourceType'],
            isEnlarged: existingTrack ? existingTrack.isEnlarged : false,
            hasAudio: user.hasAudio,
            hasVideo: user.hasVideo,
          }
        }),
      ]
    });
  }, [localCameraTrack, remoteUsers, userData.uid, microphone.isEnabled, camera.isEnabled]);

  return (
    <div className={cn('flex min-w-screen flex-col-reverse md:flex-row', {
      'h-[60vh]': userData.isClient, // for buttons to remain visible on palm devices
      'h-[70vh]': userData.isHost,
      'invisible': !showVideoTracks,
    })}>
      <div className="flex-grow rounded-2xl shadow-xl overflow-hidden relative">
        {/* Render the main video */}
        {videoTracks.filter(video => video.isEnlarged).map(video => (
          <Fragment key={video.uid}>
            {isRecording &&
              <span className="absolute z-10 left-0 top-0 bg-slate-700/50 p-2 text-sm text-white rounded-xl flex items-center">
                <span className="RecordingCircle mr-2" />
                Recording
              </span>
            }

            <VideoDisplay
              className={cn('MainVideo', {
                'is-disabled': !video.source,
                'is-disabledMirror': !isMainVideoMirrored,
              })}
              onClick={handleVideoClick} videoTrack={video} />
          </Fragment>
        ))}
      </div>

      {videoTracks.length > 1 && (
        <div className="flex flex-row md:flex-col -ml-4 md:ml-0">
          {/* Render thumbnails */}
          {videoTracks.filter(video => !video.isEnlarged).map(video => (
            <div className="rounded-2xl shadow-xl overflow-hidden w-32 h-24 lg:w-64 lg:h-48 ml-4 mb-4 relative" key={video.uid}>
              <VideoDisplay onClick={handleVideoClick} videoTrack={video} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
