import { SVGProps } from "react";

export default function UiMicrophoneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.2629 1H12.2624C10.1017 1 8.3501 2.79631 8.3501 5.01217V12.6381C8.3501 14.8539 10.1017 16.6502 12.2624 16.6502H12.2629C14.4236 16.6502 16.1752 14.8539 16.1752 12.6381V5.01217C16.1752 2.79631 14.4236 1 12.2629 1Z" fill="#A0A0A9" />
      <path d="M18.1313 8C18.248 8 18.3633 8.02483 18.4706 8.07293C18.5767 8.12055 18.6726 8.18992 18.7525 8.27654C18.8317 8.36222 18.8941 8.46336 18.9365 8.57421C18.9785 8.684 19 8.80138 19 8.91988V12.4614C19 16.2176 16.3424 19.3622 12.8944 19.7164L13.118 19.689V21.66L15.3445 21.6607C15.5402 21.6607 15.7281 21.7303 15.8795 21.8555L15.9657 21.9371C16.1249 22.1094 16.2134 22.3404 16.2134 22.5803C16.2134 22.8202 16.1249 23.0513 15.9657 23.2236C15.8029 23.3997 15.5792 23.5 15.3445 23.5H9.15548C8.92085 23.5 8.69719 23.3998 8.53425 23.2236C8.37504 23.0513 8.28658 22.8202 8.28658 22.5803C8.28658 22.3405 8.37503 22.1094 8.53425 21.9371C8.69708 21.7609 8.92077 21.6607 9.15548 21.6607L11.381 21.66V19.69C8.11679 19.2332 5.61591 16.2733 5.50392 12.7113L5.5 12.4618V8.91988C5.5 8.80148 5.52158 8.6841 5.56363 8.57424C5.60602 8.4635 5.66847 8.36238 5.74766 8.27669C5.82785 8.18991 5.92369 8.12063 6.02982 8.07305C6.13697 8.02504 6.25228 8.00022 6.3689 8.00022C6.48553 8.00022 6.60084 8.02503 6.70801 8.07306C6.81411 8.12062 6.90993 8.1899 6.99013 8.27667C7.06933 8.36236 7.13177 8.46347 7.17418 8.57424C7.21623 8.6841 7.23781 8.80148 7.23781 8.91988V12.4618C7.23781 15.4707 9.49078 17.911 12.2498 17.911C15.0089 17.911 17.2622 15.4706 17.2622 12.4618V8.92016C17.2621 8.80167 17.2836 8.68416 17.3257 8.57414C17.3681 8.46332 17.4306 8.36213 17.5098 8.2764C17.59 8.18959 17.6859 8.12028 17.7921 8.07274C17.8992 8.02475 18.0146 8 18.1313 8Z" fill="#A0A0A9" />
    </svg>
  );
}
