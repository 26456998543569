import { SVGProps } from "react";

export default function UiTrustPilotIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      {...props}
    >
      <path
        fill="#00B67A"
        d="M14.163 12.082l2.26 6.947-5.92-4.296-5.926 4.296 2.266-6.947L.917 7.78l7.32.005L10.503.833l2.26 6.953h7.32l-5.92 4.296z"
      ></path>
      <path
        fill="#005128"
        d="M10.5 14.755l3.64-2.655.504 1.579-4.144 1.076z"
      ></path>
    </svg>
  );
}
