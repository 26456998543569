import { createContext, useContext } from 'react';

import type * as Sentry from '@/sentry/init';

export type SentryContextType = {
  addBreadcrumb: typeof Sentry.addBreadcrumb,
  captureException: typeof Sentry.captureException,
}

export const defaultSentryContext = {
  addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    console.info(breadcrumb);
  },
  captureException(error: Error) {
    console.error(error);

    return error.name;
  }
};

export const SentryContext = createContext<SentryContextType>(defaultSentryContext);

export function useSentry() {
  return useContext(SentryContext);
}
