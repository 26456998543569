import { createContext, useContext, useEffect } from 'react';

import type { AnalyticsData } from '@/app/mutations/AnalyticsMutation/fetchAnalytics';

export type AnalyticsGetData = (data: AnalyticsData) => AnalyticsData;

interface BaseAnalyticsContext {
  data: AnalyticsData;
  track(getData: AnalyticsGetData): void;
}

export const AnalyticsContext = createContext<BaseAnalyticsContext>(null!);

interface TypedAnalyticsContext<D extends AnalyticsData> extends BaseAnalyticsContext {
  data: D;
  track(getData: (data: D) => D): void;
}

export function useAnalytics<D extends AnalyticsData>() {
  return useContext(AnalyticsContext) as TypedAnalyticsContext<D>;
}

export function useAnalyticsTrack<D extends AnalyticsData>(getData: (data: D) => D) {
  const { track } = useAnalytics<D>();

  useEffect(function trackAnalytics() {
    track(getData);
  }, [getData, track]);
}
