import { createContext, useContext } from 'react';

import type { ToastProps } from '@/components/Toast/Toast';

interface ImperativeToasts {
  publish(toast: ToastProps): void;
}

export const ToastsContext = createContext<ImperativeToasts>(null!);

export function useToast() {
  return useContext(ToastsContext);
}
