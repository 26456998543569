import { SVGProps } from "react";

export default function UiGoogleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      {...props}
    >
      <path
        fill="#3B7CEC"
        d="M20.092 10.166c0-.662-.059-1.29-.159-1.9H10.5v3.775h5.401c-.242 1.24-.951 2.286-2.003 2.998v2.512h3.222c1.887-1.75 2.972-4.329 2.972-7.385z"
      ></path>
      <path
        fill="#2CA14C"
        d="M10.534 20c2.696 0 4.95-.897 6.598-2.416l-3.212-2.49c-.898.597-2.038.963-3.386.963-2.605 0-4.81-1.752-5.6-4.118H1.622v2.565A9.972 9.972 0 0010.534 20z"
      ></path>
      <path
        fill="#F0B400"
        d="M4.888 11.911A5.823 5.823 0 014.57 10c0-.668.117-1.31.317-1.911V5.51H1.574a9.917 9.917 0 000 8.98l3.314-2.579z"
      ></path>
      <path
        fill="#E33E2A"
        d="M10.55 3.943c1.475 0 2.792.507 3.834 1.495l2.85-2.84C15.51.989 13.25 0 10.55 0a9.992 9.992 0 00-8.927 5.496L4.94 8.06c.791-2.366 3-4.118 5.61-4.118z"
      ></path>
    </svg>
  );
}
