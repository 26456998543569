import {
  useRTCClient,
  useClientEvent,
  useRemoteUsers,
} from 'agora-rtc-react';
import { useCallback, useState } from 'react';

import Button from '@/components/Button';

import IconPhone from '@/assets/icons/phone.svg?react';

import ButtonCamera from '@/app/components/ButtonCamera';
import ButtonMicrophone from '@/app/components/ButtonMicrophone';
import ButtonScreenShare from '@/app/components/ButtonScreenShare';
import ButtonSettings from '@/app/components/ButtonSettings';
import ButtonSwitchCamera from '@/app/components/ButtonSwitchCamera';
import ButtonVirtualBackground from '@/app/components/ButtonVirtualBackground';
import { ScreenPreloader } from '@/app/components/Preloader';
import Title from '@/app/components/Title';
import VideoTracksDisplay from '@/app/components/VideoTracksDisplay';
import { useAIDenoiserExtension } from '@/app/extensions/AIDenoiser/context';
import Layout from '@/app/Layout';
import { useUserData } from '@/app/queries/MeetingQuery/context';
import useSoundEffects from '@/app/hooks/useSoundEffects';

import './MeetingView.css';

import { useAppContext } from '@/app/AppContext';

import usePublish from '@/app/hooks/usePublish';
import useAutoAdjustStreamQuality from '@/app/hooks/useAutoAdjustStreamQuality';
import AnalyticsSync from '@/app/analytics/components/AnalyticsSync';

import DialogFeedback from '@/app/components/DialogFeedback';

interface MeetingViewProps {
  onEndCall(): void;
}

export default function MeetingView({ onEndCall }: MeetingViewProps) {
  const [showVideoTracks, setShowVideoTracks] = useState(true);
  const userData = useUserData();

  const remoteUsers = useRemoteUsers();
  const { playJoinSound } = useSoundEffects();

  const agoraClient = useRTCClient();

  useAutoAdjustStreamQuality(agoraClient);

  useClientEvent(agoraClient, 'user-joined', () => {
    playJoinSound();
  });

  const { camera, microphone } = useAppContext();

  const onEndCallHandler = useCallback(() => {
    if (microphone.track?.isPlaying) {
      microphone.track.stop();
    }
    if (microphone.isEnabled) {
      microphone.toggleDevice()
    }
    if (camera.track?.isPlaying) {
      camera.track.stop();
    }
    if (camera.isEnabled) {
      camera.toggleDevice()
    }
    setShowVideoTracks(false);
  }, [microphone, camera]);

  const isCameraReady = !(camera.isLoading || camera.error);
  const isMicReady = !(microphone.isLoading || microphone.error);

  const isLoading = camera.isLoading || microphone.isLoading;

  usePublish(microphone.track, isMicReady);
  usePublish(camera.track, isCameraReady);

  useAIDenoiserExtension(
    { track: microphone.track },
    userData.isClient && !isMicReady
  );

  if (isLoading) {
    return <ScreenPreloader />;
  }

  return (
    <Layout>
      <AnalyticsSync />
      <Title />
      <VideoTracksDisplay {...{ localCameraTrack: camera.track!, remoteUsers, showVideoTracks }} />
      <div className="flex justify-center items-center p-2 mt-3">
        {userData.isClient &&
          <>
            <ButtonSwitchCamera />
            <div className="mx-2" />
          </>
        }
        <ButtonMicrophone />
        <ButtonCamera />
        <div className="mx-2" />
        {userData.isHost &&
          <>
            <ButtonScreenShare />
            <ButtonVirtualBackground localCameraTrack={camera.track!} />
          </>
        }
        <ButtonSettings />
        <div className="mx-2" />
        <DialogFeedback onEndCall={onEndCall}>
          <Button
            title="End call"
            onClick={onEndCallHandler}
            className="mx-1"
            variant="destructive"
            size="icon"
            faded={!showVideoTracks}>
            <IconPhone className="w-6 h-6" />
          </Button>
        </DialogFeedback>
      </div>
    </Layout>
  );
}
