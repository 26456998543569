import { AIDenoiserExtension as AgoraAIDenoiserExtension } from 'agora-extension-ai-denoiser';
import type { IAIDenoiserProcessor } from 'agora-extension-ai-denoiser';
import type { IMicrophoneAudioTrack } from 'agora-rtc-react';

import BaseExtension from '@/app/extensions/BaseExtension';

const ASSETS_PATH = '/wasms/denoiser';

export default class AIDenoiserExtension extends BaseExtension<IAIDenoiserProcessor, IMicrophoneAudioTrack> {
  constructor() {
    super(new AgoraAIDenoiserExtension({ assetsPath: ASSETS_PATH }))
  }
}
