import { useAppContext } from '@/app/AppContext';
import Button from '@/components/Button';

import IconVideo from '@/assets/icons/video.svg?react';
import { EVENTS, gtag } from '@/lib/gtag';

export default function ButtonCamera() {
  const appState = useAppContext();

  const onButtonClick = () => {
    appState.camera.toggleDevice();

    gtag(EVENTS.TOGGLE_DEVICE, { device: 'camera' });
  };

  return (
    <Button
      title="Camera on/off"
      onClick={onButtonClick}
      className="mx-1"
      variant="default"
      size="icon"
      faded={!appState.camera.isEnabled}>
      <IconVideo className="w-6 h-6" />
    </Button>
  );
}
