import { SVGProps } from "react";

export default function UiThumbtackIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#009FD9"
        fillRule="evenodd"
        d="M10 0c5.523 0 10 4.477 10 10s-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0zM8.743 9.433c.572-.754 1.251-1.068 2.322-1.134v5.111c0 1.464-.389 2.433-1.147 3.597-.73-1.118-1.175-2.04-1.175-3.597V9.433zM5.054 5.306h9.973v2.04H5.054v-2.04z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
