import React, { useEffect, useState } from 'react';

import Button from '@/components/Button';

import UiYelpIcon from '@/icons/UiYelpIcon';
import UiGoogleIcon from '@/icons/UiGoogleIcon';
import UiHomeAdvisorIcon from '@/icons/UiHomeAdvisorIcon';
import UiTrustPilotIcon from '@/icons/UiTrustPilotIcon';
import UiAngiIcon from '@/icons/UiAngiIcon';
import UiThumbtackIcon from '@/icons/UiThumbtackIcon';


interface ButtonReviewProps {
  url: string;
}

const urlMappings = [
  { pattern: 'www.yelp.com', icon: <UiYelpIcon className="w-6 h-6" />, text: 'Yelp' },
  { pattern: 'www.angi.com', icon: <UiAngiIcon className="w-6 h-6" />, text: 'Angi' },
  { pattern: 'www.homeadvisor.com', icon: <UiHomeAdvisorIcon className="w-6 h-6" />, text: 'HomeAdvisor' },
  { pattern: 'www.thumbtack.com', icon: <UiThumbtackIcon className="w-6 h-6" />, text: 'Thumbtack' },
  { pattern: 'www.trustpilot.com', icon: <UiTrustPilotIcon className="w-6 h-6" />, text: 'TrustPilot' },
  { pattern: 'g.page', icon: <UiGoogleIcon className="w-6 h-6" />, text: 'Google' },
];

const getIconAndText = (url: string) => {
  const mapping = urlMappings.find(m => url.includes(m.pattern));
  return mapping ? { icon: mapping.icon, text: mapping.text } : { icon: null, text: '' };
};

const ButtonReview: React.FC<ButtonReviewProps> = ({ url }) => {
  const [iconAndText, setIconAndText] = useState<{ icon: React.ReactNode; text: string }>({ icon: null, text: '' });

  useEffect(() => {
    setIconAndText(getIconAndText(url));
  }, [url]);

  const redirectToUrl = () => {
    if (url) {
      window.location.href = url;
    }
  };

  if (!iconAndText.icon) {
    return null;
  }

  return (
    <Button
      className="flex gap-1 text-black bg-white border border-[#E2E2EA] text-base font-semibold leading-6 mx-1 mt-3 w-full"
      variant="default"
      size="icon"
      onClick={redirectToUrl}
    >
      {iconAndText.icon}
      <h1>{iconAndText.text}</h1>
    </Button>
  );
};

export default ButtonReview;
