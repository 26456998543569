import { API_BASE } from '@/app/config';

export interface FetchRecordingParams {
  action: 'start' | 'stop';
  meeting_id: string;
}

export default async function fetchRecording(params: FetchRecordingParams) {
  await fetch(`${API_BASE}/manage-recording`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
}
