import { useCallback, useState } from 'react';

import { ICameraVideoTrack } from 'agora-rtc-react';

import Button from '@/components/Button';
import { useVirtualBackgroundExtension } from '@/app/extensions/VirtualBackground/context';

import IconSparkles from '@/assets/icons/sparkles.svg?react';
import { EVENTS, gtag } from '@/lib/gtag';

export interface ButtonVirtualBackgroundProps {
  localCameraTrack: ICameraVideoTrack;
}

export default function ButtonVirtualBackground({ localCameraTrack }: ButtonVirtualBackgroundProps) {
  const [isVirtualBGEnabled, setVirtualBGEnabled] = useState(true);

  const { isCompatible } = useVirtualBackgroundExtension({ track: localCameraTrack }, isVirtualBGEnabled);

  const toggleVirtualBG = useCallback(() => {
    gtag(EVENTS.TOGGLE_VIRTUAL_BACKGROUND);

    setVirtualBGEnabled(isVirtualBGEnabled => !isVirtualBGEnabled);
  }, []);


  return (
    <Button
      disabled={!isCompatible}
      title="Enable virtual background"
      onClick={toggleVirtualBG}
      className="mx-1"
      variant="default"
      size="icon"
      faded={!isVirtualBGEnabled}>
      <IconSparkles className="w-6 h-6" />
    </Button>
  );
}
