import { useMemo, useState } from 'react';

import * as ToastPrimitive from '@radix-ui/react-toast';
import type { ToastProviderProps } from '@radix-ui/react-toast';

import { ToastsContext } from '@/components/Toast/context';
import Toast, { ToastProps } from '@/components/Toast/Toast';

const ONE_MINUTE_IN_MS = 60 * 1000;
const TOAST_DEFAULT_DURATION = ONE_MINUTE_IN_MS;

export default function ToastsProvider(props: ToastProviderProps) {
  const [toasts, setToasts] = useState([] as ToastProps[]);

  const imperativeToasts = useMemo(() => ({
    publish(toast: ToastProps) {
      setToasts(toasts => ([...toasts, toast]))
    },
  }), []);

  return (
    <ToastPrimitive.Provider duration={TOAST_DEFAULT_DURATION} {...props}>
      <ToastsContext.Provider value={imperativeToasts}>
        {props.children}
        {toasts.map(toast => (
          <Toast key={`${toast.variant}: ${toast.text}`} {...toast} className="fixed top-4 right-4 left-4" />
        ))}
      </ToastsContext.Provider>
      <ToastPrimitive.Viewport />
    </ToastPrimitive.Provider>
  );
}
