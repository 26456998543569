import App from '@/app/App';

import withAnalytics from '@/app/analytics/withAnalytics';
import withClientAIDenoiserExtension from '@/app/extensions/AIDenoiser/withClientAIDenoiserExtension';
import withHostVirtualBackgroundExtension from '@/app/extensions/VirtualBackground/withHostVirtualBackgroundExtension';
import withRecordingMutation from '@/app/mutations/RecordingMutation/withRecordingMutation';
import withMeetingQuery from '@/app/queries/MeetingQuery/withMeetingQuery';
import withAgoraClient from '@/app/withAgoraClient';
import withSoundEffects from '@/app/soundEffects/withSoundEffects';

const ConnectedApp = (
  withAgoraClient(
    withAnalytics(
      withMeetingQuery(
        withSoundEffects(
          withRecordingMutation(
            withClientAIDenoiserExtension(
              withHostVirtualBackgroundExtension(
                App
              )
            )
          )
        )
      )
    )
  )
);

export default ConnectedApp;
