import { useCallback, useEffect, useState } from 'react';

import AgoraRTC from 'agora-rtc-sdk-ng';

import FieldDeviceSelect from '@/app/components/FieldDeviceSelect';
import { useAppContext } from '../AppContext';
import { EVENTS, gtag } from '@/lib/gtag';


export default function FieldCameraSelect() {
  const { camera } = useAppContext();

  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);

  const onValueChange = useCallback((deviceId: MediaDeviceInfo['deviceId']) => {
    camera.changeDevice(deviceId);

    gtag(EVENTS.CHANGE_DEVICE, { device: 'camera' });
  }, [camera]);

  useEffect(function initDevices() {
    if (camera.isPermissionGranted) {
      AgoraRTC.getCameras().then(setCameras);
    }
  }, [camera]);

  return (
    <FieldDeviceSelect
      devices={cameras}
      onValueChange={onValueChange}
      title="Camera"
      value={camera.device} />
  );
}
