import { ReactNode, useCallback, useRef } from 'react';
import { SoundEffectsContext } from './context';

interface ITimerMap {
  [key: string]: NodeJS.Timeout,
}

export default function withSoundEffects<T extends object>(
  Component: (props: T) => ReactNode,
) {
  return function ConnectedComponent(props: T) {
    const soundTimers = useRef<ITimerMap>({});

    // function to play any sound in our app
    const playSound = useCallback((soundUrl: string, isDebounced: boolean) => {
      const sound = new Audio(soundUrl);
      sound.play().catch(
        () => console.debug("Unable to autoplay")
      ).finally(() => {
        if (isDebounced) {
          clearTimeout(soundTimers.current[soundUrl]);
        }
      });
    }, []);

    // usefull wrapper to handle debouncing, this is a function we are going to use
    const play = useCallback((soundUrl: string, debounceMs: number) => {
      if (!debounceMs) {
        playSound(soundUrl, false);
        return;
      }

      if (soundTimers.current[soundUrl]) {
        clearTimeout(soundTimers.current[soundUrl]);
        delete soundTimers.current[soundUrl]
      }

      const timer = setTimeout(
        () => playSound(soundUrl, true),
        debounceMs
      );
      soundTimers.current[soundUrl] = timer;
    }, [playSound]);

    return (
      <SoundEffectsContext.Provider value={{ play }}>
        <Component {...props} />
      </SoundEffectsContext.Provider>
    )
  };
}
