import { SVGProps } from "react";

export default function UiUserFormIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="114"
      height="115"
      viewBox="0 0 114 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M38.6773 32.8571C38.6773 22.6497 46.8801 14.375 56.9987 14.375C67.1174 14.375 75.3202 22.6497 75.3202 32.8571C75.3202 43.0645 67.1174 51.3393 56.9987 51.3393C46.8801 51.3393 38.6773 43.0645 38.6773 32.8571Z" fill="#75747E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.4145 90.7897C23.7294 72.3497 38.6447 57.5 56.9987 57.5C75.3532 57.5 90.2688 72.3504 90.583 90.7911C90.6038 92.014 89.9054 93.1336 88.8033 93.6437C79.1176 98.127 68.3439 100.625 57 100.625C45.6552 100.625 34.8804 98.1266 25.194 93.6424C24.092 93.1323 23.3936 92.0127 23.4145 90.7897Z" fill="#75747E" />
    </svg>
  );
}
