import { ReactNode, useCallback, useRef } from 'react';

import { AIDenoiserExtensionContext } from '@/app/extensions/AIDenoiser/context';
import AIDenoiserExtension from '@/app/extensions/AIDenoiser/AIDenoiserExtension';
import { useUserData } from '@/app/queries/MeetingQuery/context';

export default function withClientAIDenoiserExtension<T extends object>(
  Component: (props: T) => ReactNode,
) {
  let _extension: AIDenoiserExtension; // save singleton

  return function ConnectedComponent(props: T) {
    const userData = useUserData();

    const createExtension = useCallback(() => _extension ?? (_extension = new AIDenoiserExtension()), []);

    const extensionRef = useRef(userData.isClient ? createExtension() : null);

    return (
      <AIDenoiserExtensionContext.Provider value={extensionRef.current}>
        <Component {...props} />
      </AIDenoiserExtensionContext.Provider>
    );
  };
}
