import { API_BASE } from '@/app/config';

export type AnalyticsData = Record<string, undefined | string | boolean | number>; // flat JSON

export interface FetchAnalyticsParams {
  meeting_hash: string;
  metrics: AnalyticsData;
}

export default function fetchAnalytics(params: FetchAnalyticsParams) {
  return fetch(`${API_BASE}/meeting_metrics`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
}
