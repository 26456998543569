import { ReactNode, useState, useEffect } from 'react';

import * as Dialog from '@radix-ui/react-dialog';
import * as RadioGroup from '@radix-ui/react-radio-group';

import UiStarIcon from '@/icons/UiStarIcon';
import { useMeetingData } from '../queries/MeetingQuery/context';
import useFeedbackMutation from '../mutations/FeedbackMutation/useFeedbackMutation';
import ButtonReview from './ButtonReview';
import { EVENTS, gtag } from '@/lib/gtag';

interface DialogFeedbackProps {
  children: ReactNode;
  onEndCall(): void;
}

export default function DialogFeedback({ children, onEndCall }: DialogFeedbackProps) {
  const [selectedStar, setSelectedStar] = useState(0);
  const [hoverStar, setHoverStar] = useState(0);
  const [reviewUrls, setReviewUrls] = useState<string[]>([]);
  const { mutate } = useFeedbackMutation();
  const data = useMeetingData();

  useEffect(() => {
    setReviewUrls(data.meeting_data.review_urls || []);
  }, [data]);

  const handleStarSelect = (index: number) => {
    if (selectedStar !== 0) return;
    setSelectedStar(index);
    setHoverStar(0);
    const { meeting_urn: urn, user_id } = data.meeting_data;
    mutate({ urn, user: Number(user_id), nps: index });

    gtag(EVENTS.ADD_RATING, { nps: index });
  };

  const handleOnOpenChange = (open: boolean) => {
    if (!open) {
      onEndCall();
    }
  };

  const createReviewButtons = (urls: string[]) => urls.map((url, index) => <ButtonReview key={index} url={url} />);

  return (
    <Dialog.Root onOpenChange={handleOnOpenChange}>
      <Dialog.Trigger asChild>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 data-[state=open]:animate-overlayShow" />
        <Dialog.Content className="dialog-content data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-6 shadow-lg focus:outline-none">
          <Dialog.Title className="font-hind-vadodara text-[18px] font-bold leading-6 text-center mb-1">
            {selectedStar === 0 ? (
              <>
                How was your live video <br className="md:hidden" />estimate?
              </>
            ) : (
              <>
                Thank you for your <br className="md:hidden" />feedback!
              </>
            )}
          </Dialog.Title>
          <div className="flex justify-center items-center mt-4">
            <RadioGroup.Root
              className="flex"
              value={selectedStar.toString()}
              aria-label="Star rating"
            >
              {[1, 2, 3, 4, 5].map((star) => (
                <RadioGroup.Item
                  key={star}
                  value={star.toString()}
                  id={`star-${star}`}
                  className="star"
                  onClick={() => handleStarSelect(star)}
                  onMouseEnter={() => selectedStar === 0 && setHoverStar(star)}
                  onMouseLeave={() => selectedStar === 0 && setHoverStar(0)}
                  onTouchStart={() => selectedStar === 0 && setHoverStar(star)}
                  onTouchEnd={() => selectedStar === 0 && setHoverStar(0)}
                >
                  <UiStarIcon
                    selectedStar={selectedStar >= star}
                    hoverStar={hoverStar >= star}
                  />
                </RadioGroup.Item>
              ))}
            </RadioGroup.Root>
          </div>
          {(selectedStar !== 0 && selectedStar > 3 && reviewUrls.length !== 0) && (
            <div className="flex flex-col text-center bg-[#F0F0F7] min-h-[16.25rem] p-5 mt-4">
              <div className="font-bold leading-6 text-[18px]">
                Would you like to <br className="md:hidden" />share a review?
              </div>
              {createReviewButtons(reviewUrls)}
              <a className="text-base font-semibold leading-6 text-[#0970CE] mt-6 cursor-pointer" onClick={onEndCall}>
                Maybe Later
              </a>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
