import { useRemoteUsers } from 'agora-rtc-react';
import { createContext, useContext, useEffect, useRef } from 'react';

const STOP_RECORDING_DELAY = 180000; // 3 minutes in milliseconds

export interface Recording {
  isRecording: boolean;
  start(): void;
  stop(): void;
}

export const RecordingContext = createContext<Recording>(null!);

export function useRecording() {
  return useContext(RecordingContext);
}

export function useAutoRecording({ joined }: { joined: boolean }, isEnabled: boolean) {
  const recording = useRecording();
  const remoteUsers = useRemoteUsers();
  const stopRecordingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!joined || !isEnabled || !recording.isRecording) {
      return;
    }
    // If all remote users have left the meeting and we are still recording
    if (remoteUsers.length === 0 && recording.isRecording) {
      // Set a timeout for 3 minutes before stopping the recording
      stopRecordingTimeoutRef.current = setTimeout(() => {
        recording.stop();
      }, STOP_RECORDING_DELAY);
    } else {
      // If remote users rejoin, clear the timeout to prevent stopping the recording
      if (stopRecordingTimeoutRef.current) {
        clearTimeout(stopRecordingTimeoutRef.current);
        stopRecordingTimeoutRef.current = null;
      }
    }

    // Cleanup the timeout when the component unmounts or remoteUsers length changes
    return () => {
      if (stopRecordingTimeoutRef.current) {
        clearTimeout(stopRecordingTimeoutRef.current);
      }
    };
  }, [joined, isEnabled, recording, remoteUsers.length]);

  useEffect(function triggerRecording() {
    if (!isEnabled) return;
    if (joined) {
      // one more condition to avoid double recording
      if (remoteUsers.length && !recording.isRecording) {
        recording.start();
      }
    } else {
      recording.stop();
    }
  }, [joined, isEnabled, recording, remoteUsers.length]);
}
