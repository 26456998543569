import { ReactNode, useCallback, useState } from 'react';

import { AnalyticsContext, AnalyticsGetData } from '@/app/analytics/context';


export default function withAnalytics<T extends object>(
  Component: (props: T) => ReactNode,
) {
  return function ConnectedComponent(props: T) {
    const [data, setData] = useState({});

    const track = useCallback((getData: AnalyticsGetData) => {
      setData(getData)
    }, []);

    return (
      <AnalyticsContext.Provider value={{ data, track }}>
        <Component {...props} />
      </AnalyticsContext.Provider>
    );
  };
}
