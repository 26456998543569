import { createContext, useContext } from 'react';

import type { IMicrophoneAudioTrack } from 'agora-rtc-react';

import type { IBaseExtension } from '@/app/extensions/BaseExtension';
import { useExtension } from '@/app/extensions/useExtension';

export const AIDenoiserExtensionContext = createContext<IBaseExtension<IMicrophoneAudioTrack> | null>(null);

export function useAIDenoiserExtension(
  { track }: { track: IMicrophoneAudioTrack | null },
  isEnabled: boolean,
) {
  const extension = useContext(AIDenoiserExtensionContext);

  return useExtension(extension, { track }, isEnabled);
}
