import Select, { SelectItem, SelectProps } from '@/components/Select';

interface DeviceSelect extends SelectProps {
  title: string;
  devices: MediaDeviceInfo[];
}

export default function FieldDeviceSelect(props: DeviceSelect) {
  const { devices, title, ...selectProps } = props;

  return (
    <fieldset className="mb-[15px] flex items-center gap-5">
      <label className="text-violet11 min-w-[90px] text-right text-[15px]" htmlFor="username">
        {title}
      </label>
      <Select {...selectProps}>
        {devices.map(device =>
          <SelectItem key={device.deviceId} value={device.deviceId}>{device.label}</SelectItem>
        )}
      </Select>
    </fieldset>
  )
}
