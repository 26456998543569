import { useCallback, useEffect, useState } from 'react';

import AgoraRTC from 'agora-rtc-sdk-ng';

import FieldDeviceSelect from '@/app/components/FieldDeviceSelect';
import { useAppContext } from '../AppContext';
import { EVENTS, gtag } from '@/lib/gtag';

export default function FieldMicrophoneSelect() {
  const { microphone } = useAppContext();
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);

  const onValueChange = useCallback((deviceId: MediaDeviceInfo['deviceId']) => {
    microphone.changeDevice(deviceId);

    gtag(EVENTS.CHANGE_DEVICE, { device: 'microphone' });
  }, [microphone]);

  useEffect(function initDevices() {
    if (microphone.isPermissionGranted) {
      AgoraRTC.getMicrophones().then(setMicrophones);
    }
  }, [microphone]);

  return (
    <FieldDeviceSelect
      devices={microphones}
      onValueChange={onValueChange}
      title="Microphone"
      value={microphone.device} />
  );
}
