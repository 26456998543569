export default function Logo() {
  return (
    <img
      className="self-stretch h-[26px] relative max-w-full overflow-hidden shrink-0 object-cover"
      loading="lazy"
      alt=""
      src="/logo26@2x.png"
    />
  );
}
