import AgoraRTC, {
  AgoraRTCReactError, ICameraVideoTrack, IMicrophoneAudioTrack,
  useLocalCameraTrack, useLocalMicrophoneTrack
} from 'agora-rtc-react';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';

import useChangeDeviceForLocalTrack, { ChangeDeviceProps, LocalCameraTrack, LocalMicrophoneTrack } from '@/app/hooks/useChangeDeviceForLocalTrack';
import useHighResAdjustedVideoProfile from '@/app/hooks/useHighResAdjustedVideoProfile';
import useDeviceToggle from './hooks/useDeviceToggle';
import { useSingleTrackInstance } from './hooks/useSingleTrackInstance';
import { Permissions } from './types';

export interface TrackDeviceGroup<A, B> {
  track: A | null,
  isLoading: boolean,
  isEnabled: boolean,
  error: AgoraRTCReactError | null,
  device: MediaDeviceInfo['deviceId'] | undefined,
  isPermissionGranted: boolean;
  changeDevice(deviceOptions: B): Promise<void>,
  toggleDevice(): void,
}

interface SpeakerDevice {
  devices: MediaDeviceInfo[],
  deviceId: MediaDeviceInfo['deviceId'] | undefined,
  changeDevice(deviceId: string): void,
}

export interface IAppContext {
  camera: TrackDeviceGroup<ICameraVideoTrack, ChangeDeviceProps>,
  microphone: TrackDeviceGroup<IMicrophoneAudioTrack, MediaDeviceInfo['deviceId']>,
  speaker: SpeakerDevice,
  isMainVideoMirrored: boolean,
  setIsMainVideoMirrored: Dispatch<SetStateAction<boolean>>,
}

export const AppContext = createContext<IAppContext>(null!);

function useCamera(isPermissionGranted?: boolean): TrackDeviceGroup<ICameraVideoTrack, ChangeDeviceProps> {
  const encoderConfig = useHighResAdjustedVideoProfile();

  const {
    localCameraTrack,
    ...cameraOptions
  } = useLocalCameraTrack(isPermissionGranted === true, { encoderConfig });

  const {
    localTrack,
    isLoading,
    error
  } = useSingleTrackInstance(localCameraTrack, cameraOptions);

  const { device, changeDevice } = useChangeDeviceForLocalTrack(
    localTrack as LocalCameraTrack, isLoading, error
  );

  const { isEnabled, toggleDevice } = useDeviceToggle(
    localTrack as LocalCameraTrack, 'video'
  );

  return {
    track: localTrack,
    isEnabled,
    toggleDevice,
    changeDevice,
    device,
    error,
    isPermissionGranted: isPermissionGranted === true,
    isLoading
  }
}

function useMicrophone(isPermissionGranted?: boolean): TrackDeviceGroup<IMicrophoneAudioTrack, MediaDeviceInfo['deviceId']> {
  const {
    localMicrophoneTrack,
    ...microphoneOptions
  } = useLocalMicrophoneTrack(isPermissionGranted === true);

  const {
    localTrack,
    isLoading,
    error,
  } = useSingleTrackInstance(localMicrophoneTrack, microphoneOptions);

  const { device, changeDevice } = useChangeDeviceForLocalTrack(
    localTrack as LocalMicrophoneTrack, isLoading, error
  );

  const { isEnabled, toggleDevice } = useDeviceToggle(
    localMicrophoneTrack as LocalMicrophoneTrack, 'audio'
  );

  return {
    track: localTrack,
    isEnabled,
    toggleDevice,
    changeDevice,
    device,
    error,
    isPermissionGranted: isPermissionGranted === true,
    isLoading
  }
}

function useSpeaker(): SpeakerDevice {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [deviceId, setDeviceId] = useState<MediaDeviceInfo['deviceId']>();

  useEffect(function fetchInitialSpeakerDevices() {
    AgoraRTC.getPlaybackDevices().then(playbackDevices => {
      setDevices(playbackDevices);
    }, (error) => {
      console.error('Failed to fetch playback devices', error);
    });
  }, []);

  useEffect(function setInitialSpeaker() {
    if (devices.length) {
      setDeviceId(devices[0].deviceId);
    }
  }, [devices]);

  const changeDevice = (deviceId: string) => {
    setDeviceId(deviceId);
  }

  return {
    devices,
    deviceId,
    changeDevice
  }
}

export function usePrepareAppState(permissions: Permissions): IAppContext {
  const camera = useCamera(permissions.video);
  const microphone = useMicrophone(permissions.audio);
  const speaker = useSpeaker();

  // The call start with user-facing camera, i.e. the value is true.
  const [isMainVideoMirrored, setIsMainVideoMirrored] = useState(true);

  return {
    camera,
    microphone,
    speaker,
    isMainVideoMirrored,
    setIsMainVideoMirrored,
  };
}

export function useAppContext(): IAppContext {
  return useContext(AppContext);
}
