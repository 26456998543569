import * as ReactSelect from '@radix-ui/react-select';
import type { SelectProps } from '@radix-ui/react-select';

export type { SelectProps } from '@radix-ui/react-select';

import IconChevronDown from '@/assets/icons/chevron-down.svg?react';

export default function Select({ children, ...props }: SelectProps) {
  return (
    <ReactSelect.Root {...props}>
      <ReactSelect.Trigger className="inline-flex items-center justify-between rounded px-4 text-sm leading-none w-full h-[35px] gap-[5px] bg-white text-violet11 shadow-[0_2px_10px] shadow-black/10 hover:bg-mauve3 focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-violet9 outline-none">
        <ReactSelect.Value />
        <ReactSelect.Icon className="min-w-[24px] text-violet11">
          <IconChevronDown />
        </ReactSelect.Icon>
      </ReactSelect.Trigger>
      <ReactSelect.Portal>
        <ReactSelect.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
          <ReactSelect.Viewport className="p-[5px]">
            <ReactSelect.Group>
              {children}
            </ReactSelect.Group>
          </ReactSelect.Viewport>
        </ReactSelect.Content>
      </ReactSelect.Portal>
    </ReactSelect.Root>
  );
}

