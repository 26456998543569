import { ReactNode, useCallback, useRef } from 'react';

import { VirtualBackgroundExtensionContext } from '@/app/extensions/VirtualBackground/context';
import VirtualBackgroundExtension from '@/app/extensions/VirtualBackground/VirtualBackgroundExtension';
import { useUserData } from '@/app/queries/MeetingQuery/context';

export default function withHostVirtualBackgroundExtension<T extends object>(
  Component: (props: T) => ReactNode,
) {
  let _extension: VirtualBackgroundExtension; // save singleton

  return function ConnectedComponent(props: T) {
    const userData = useUserData();

    const createExtension = useCallback(() => _extension ?? (_extension = new VirtualBackgroundExtension()), []);

    const extensionRef = useRef(userData.isHost ? createExtension() : null);

    return (
      <VirtualBackgroundExtensionContext.Provider value={extensionRef.current}>
        <Component {...props} />
      </VirtualBackgroundExtensionContext.Provider>
    );
  };
}
