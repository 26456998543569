import { BACKEND_API_HOST } from '@/app/config';

export interface sendFeedbackParams {
  urn: string;
  user: number;
  nps: number;
}

export default async function sendFeedback(params: sendFeedbackParams) {
  await fetch(`${BACKEND_API_HOST}/api/feedback/add-video-meeting-feedback/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  });
}
