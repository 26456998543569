import { useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import NiceModal from '@ebay/nice-modal-react';

import ToastsProvider from '@/components/Toast/ToastsProvider';

import ErrorBoundary from '@/lib/ErrorBoundary';
import { SentryContext } from '@/sentry/context';
import useSentryInit from '@/sentry/useSentryInit';

import Error404 from '@/pages/404';
import Call from '@/pages/Call';
import Index from '@/pages/Main';
import useNetworkStatus from '@/app/hooks/useNetworkStatus';
import '@/modals/register';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
  },
  {
    path: '/call/:hash',
    element: (
      <ErrorBoundary>
        <Call />
      </ErrorBoundary>
    ),
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

export default function MainComponent() {
  const Sentry = useSentryInit();

  const isOnline = useNetworkStatus();

  useEffect(() => {
    queryClient.setDefaultOptions({
      queries: {
        ...queryClient.getDefaultOptions().queries,
        // disable all queries while in offline mode, because they will fail anyway.
        // queries will be all re-evaluated when app goes online back
        enabled: isOnline
      },
    });
  }, [isOnline]);

  return (
    <SentryContext.Provider value={Sentry}>
      <ErrorBoundary>
        <NiceModal.Provider>
          <ToastsProvider>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
            </QueryClientProvider>
          </ToastsProvider>
        </NiceModal.Provider>
      </ErrorBoundary>
    </SentryContext.Provider>
  );
}
