import { SVGProps } from "react";

export default function UiCloseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M1.95053 1.45004C2.16647 1.2341 2.51658 1.2341 2.73252 1.45004L4.00038 2.7179L5.26824 1.45004C5.48418 1.2341 5.83429 1.2341 6.05023 1.45004C6.26617 1.66598 6.26617 2.01609 6.05023 2.23203L4.78237 3.49989L6.05023 4.76775C6.26617 4.98369 6.26617 5.3338 6.05023 5.54974C5.83429 5.76568 5.48418 5.76568 5.26824 5.54974L4.00038 4.28188L2.73252 5.54974C2.51658 5.76568 2.16647 5.76568 1.95053 5.54974C1.73459 5.3338 1.73459 4.98369 1.95053 4.76775L3.21839 3.49989L1.95053 2.23203C1.73459 2.01609 1.73459 1.66598 1.95053 1.45004Z" fill="white" />
    </svg>
  );
}
