import { ReactNode, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { DefaultError } from '@/app/components/Error';
import { ScreenPreloader } from '@/app/components/Preloader';

import { MeetingData, MeetingDataContext } from '@/app/queries/MeetingQuery/context';
import fetchMeeting from '@/app/queries/MeetingQuery/fetchMeeting';

export default function withMeetingQuery<T extends object>(
  Component: (props: T) => ReactNode,
) {
  return function ConnectedComponent(props: T) {
    const { hash } = useParams<{ hash: string }>();

    const { status, data, error, refetch } = useQuery<MeetingData>({
      queryKey: ['onsite'],
      queryFn: () => fetchMeeting(hash!),
      retry: false,
    });

    const onRefetch = useCallback(() => {
      refetch();
    }, [refetch]);

    switch (status) {
      case 'pending':
        return (
          <ScreenPreloader />
        );
      case 'error':
        return (
          <DefaultError
            status="Oops!"
            description='Retrieving meeting data failed.'
            error={error}
            onClick={onRefetch} />
        );
      case 'success':
        return (
          <MeetingDataContext.Provider value={data}>
            <Component {...props} />
          </MeetingDataContext.Provider>
        );
      default: {
        const neverType: never = status;
        throw new Error(`Unhandled status "${neverType}" in withMeetingQuery`);
      }
    }
  };
}
