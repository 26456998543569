// useDeviceToggle.js
import { useCallback, useEffect, useState } from 'react';

import type { IMicrophoneAudioTrack, ICameraVideoTrack } from 'agora-rtc-react';

import { isPermissionDeniedError } from '@/app/utils/errors';
import { useToast } from '@/components/Toast/context';

type DeviceTrack = IMicrophoneAudioTrack | ICameraVideoTrack;

export default function useDeviceToggle(deviceTrack: DeviceTrack, deviceType: 'video' | 'audio') {
  const toast = useToast();
  const [isEnabled, setEnabled] = useState(Boolean(deviceTrack));

  const toggleDevice = useCallback(() => {
    if (deviceTrack) {
      setEnabled(prevEnabled => {
        const newEnabled = !prevEnabled;
        deviceTrack.setEnabled(newEnabled);
        return newEnabled;
      });
    }
  }, [deviceTrack]);

  useEffect(() => {
    if (deviceTrack) {
      setEnabled(Boolean(deviceTrack));
    }
  }, [deviceTrack]);

  useEffect(() => {
    if (!isEnabled) return;
    if (deviceTrack) return;

    const constraints = deviceType === 'video' ? { video: true } : { audio: true };
    navigator.mediaDevices.getUserMedia(constraints)
      .then(() => setEnabled(true))
      .catch(error => {
        setEnabled(false);

        if (!isPermissionDeniedError(error)) {
          throw error;
        }

        switch (deviceType) {
          case 'video':
            toast.publish({ text: 'Please, enable camera', variant: 'info' });
            break;
          case 'audio':
            toast.publish({ text: 'Please, enable microphone', variant: 'info' });
            break;
          default: {
            const neverType: never = deviceType;
            throw new TypeError(`Unhandled device type: ${neverType}`);
          }
        }
      });
  }, [deviceTrack, deviceType, isEnabled, toast]);

  return { isEnabled, toggleDevice };
}
