import ReactDOM from 'react-dom/client';

import MainComponent from '@/MainComponent';

import './reset.css';
import './tailwind.css';
import './fonts.css';

const root = document.getElementById('root')!;

ReactDOM.createRoot(root).render(<MainComponent />);
