import AgoraRTC from 'agora-rtc-sdk-ng';
import type { IBaseProcessor, IExtension } from 'agora-rte-extension';

export interface IBaseTrack {
  pipe(processor: IBaseProcessor): IBaseProcessor;
  unpipe(): void;
  processorDestination: IBaseProcessor;
}

export interface IBaseExtension<T> {
  isCompatible: boolean;
  isAttached: boolean;
  attach(track: T): void;
  detach(track: T): void;
}

/**
 * This class and its descendant classes are here to unify, simplify, and further abstract the usage
 * of Agora extensions. AIDenoiser is done in sync, whether VirtualBG requires extra async init
 * before attaching/detaching. When unifying with hooks, these 2 create a mess in useEffect,
 * whether this abstraction is more straightforward and can be directly used in React.Context.
 */
export default class BaseExtension<P extends IBaseProcessor, T extends IBaseTrack> implements IBaseExtension<T> {
  isCompatible: boolean;
  isAttached: boolean = false;

  protected processor: P;

  constructor(extension: IExtension<P>) {
    AgoraRTC.registerExtensions([extension]);
    this.isCompatible = extension.checkCompatibility?.() ?? false;
    this.processor = extension.createProcessor();
  }

  attach(track: T) {
    this.isAttached = true;
    track.pipe(this.processor).pipe(track.processorDestination);
    this.processor.enable();
  }

  detach(track: T) {
    this.isAttached = false;
    this.processor.disable();
    this.processor.unpipe();
    track.unpipe();
  }
}
