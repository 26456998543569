export const EVENTS = {
  SCREEN_SHARE: 'SCREEN_SHARE',
  OPEN_SETTINGS: 'OPEN_SETTINGS',
  TOGGLE_VIRTUAL_BACKGROUND: 'TOGGLE_VIRTUAL_BACKGROUND',
  ADD_RATING: 'ADD_RATING',
  JOIN_CHANNEL: 'JOIN_CHANNEL',
  TOGGLE_DEVICE: 'TOGGLE_DEVICE',
  CHANGE_DEVICE: 'CHANGE_DEVICE',
  FOCUS_STREAM: 'FOCUS_STREAM',
  SHOW_MODAL: 'SHOW_MODAL',
}

// Not using "track()" like in other projects, because we have too many "track" device
// variables and it might be confusing for devs
export function gtag(eventName: string, data?: object) {
  // BEGIN: Google Tag manager
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const dataLayer = window.dataLayer;

  if (dataLayer) {
    dataLayer.push({
      event: eventName,
      data: data,
    });
  }
  // END: Google Tag manager

  if (process.env.NODE_ENV !== 'production') {
    console.info(
      `%cEvent %c ${eventName}`,
      'color: #FF8118; font-size:24px;',
      'color: #00B9F3; font-size:24px;',
      data
    );
  }
}
