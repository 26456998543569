import { useUserData } from '@/app/queries/MeetingQuery/context';

import Logo from '@/assets/logo.svg?react';

export default function Title() {
  const userData = useUserData();

  return (
    <div className="flex flex-row items-center mb-6">
      <Logo />
      <div className="flex flex-col justify-center ml-4">
        <h1 className="text-lg text-white">
          Onsite with Ergeon
        </h1>
        <p className="text-xs text-slate-400">
          {userData.address}
        </p>
      </div>
    </div>
  );
}
