import { useCallback, useMemo, useRef } from 'react';

import AgoraRTC, {
  useJoin,
  useLocalScreenTrack,
} from 'agora-rtc-react';

import { useAnalyticsTrack } from '@/app/analytics/context';
import { APP_ID } from '@/app/config';
import { useShareData } from '@/app/queries/MeetingQuery/context';
import usePublish from '@/app/hooks/usePublish';

export function useScreenShare(isActive: boolean) {
  const sharedData = useShareData(APP_ID);

  const agoraClient = useMemo(() => (
    AgoraRTC.createClient({
      codec: 'vp8',
      mode: 'rtc',
    })
  ), []);

  const screenShareClient = useRef(agoraClient);
  const { screenTrack, isLoading, error } = useLocalScreenTrack(true, {}, 'disable', screenShareClient.current);

  useJoin(sharedData, isActive, screenShareClient.current);

  usePublish(screenTrack, Boolean(screenTrack), screenShareClient.current);

  return { screenTrack, isLoading, error };
}

export default function useScreenShareWithAnalytics(isActive: boolean) {
  const getAnalyticsData = useCallback((prevData: { screensharingUsed: boolean }) => ({
    // don't rename this key even if you think it's a typo, it affects how metrics are saved in our db
    // ensure you've reached data team and migrated old data first if you are fixing this
    screensharingUsed: prevData.screensharingUsed || isActive,
  }), [isActive]);

  useAnalyticsTrack(getAnalyticsData);

  return useScreenShare(isActive);
}
