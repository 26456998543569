import { useEffect } from 'react';

import type { IBaseExtension, IBaseTrack } from '@/app/extensions/BaseExtension';

export function useExtension<T extends IBaseTrack>(
  extension: IBaseExtension<T> | null,
  { track }: { track: T | null },
  isEnabled: boolean,
) {
  useEffect(function onAfterInit() {
    if (!track) return;

    if (!extension) return;
    if (!extension.isCompatible) return;

    if (isEnabled) {
      extension.attach(track);
    } else {
      extension.detach(track);
    }

    return () => {
      if (extension?.isAttached) {
        extension.detach(track);
      }
    }
  }, [extension, isEnabled, track]);

  return { isCompatible: extension?.isCompatible ?? false };
}
