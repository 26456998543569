import { useMemo } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

import IconCheckCircle from '@/assets/icons/check-circle.svg?react';
import IconExclamationTriangle from '@/assets/icons/exclamation-triangle.svg?react';
import IconInformationCircle from '@/assets/icons/information-circle.svg?react';

const toastIconVariants = cva(
  'min-w-6 h-6',
  {
    variants: {
      variant: {
        default: '',
        info: 'fill-sky-500',
        destructive: 'fill-red-500',
        success: 'fill-green-500',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

type ToastIconVariantProps = VariantProps<typeof toastIconVariants>;

export default function ToastIcon({ variant }: ToastIconVariantProps) {
  const Comp = useMemo(() => {
    switch (variant) {
      case 'info':
        return IconInformationCircle;
      case 'destructive':
        return IconExclamationTriangle;
      case 'success':
        return IconCheckCircle;
      case 'default':
      case null:
      case undefined:
        return null;
      default: {
        const neverType: never = variant;
        throw new TypeError(`Unhandled toast icon variant: ${neverType}`);
      }
    }
  }, [variant]);

  if (!Comp) {
    return null;
  }

  return <Comp className={cn(toastIconVariants({ variant }))} />;
}
