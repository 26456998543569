import { AgoraRTCReactError, ICameraVideoTrack, IMicrophoneAudioTrack } from "agora-rtc-react";
import { useCallback, useEffect, useState } from "react";

export interface LocalCameraTrack extends ICameraVideoTrack {
  getMediaStreamTrackSettings(): Pick<MediaDeviceInfo, 'deviceId'>;
}

export interface LocalMicrophoneTrack extends IMicrophoneAudioTrack {
  getMediaStreamTrackSettings(): Pick<MediaDeviceInfo, 'deviceId'>;
}

export type ChangeDeviceProps = string | {
  deviceId: MediaDeviceInfo['deviceId'],
} | {
  facingMode: VideoFacingModeEnum,
}

// Hook is used to change the device used for the local track safely, e.g.,
// switching the front-facing camera of a mobile device to the back-facing.  
export default function useChangeDeviceForLocalTrack(
  track: LocalCameraTrack | LocalMicrophoneTrack,
  isLoading: boolean,
  error: AgoraRTCReactError | null
) {
  const [device, setDevice] = useState<MediaDeviceInfo['deviceId']>();

  const isTrackReady = track && !isLoading && !error;

  const changeDevice = useCallback(async (options: ChangeDeviceProps) => {
    if (!isTrackReady) {
      return;
    }
    try {
      // mic track supports only deviceId passed, so we are adding this
      // to get around ts lint issues
      if (track.trackMediaType === 'video') {
        const cameraTrack = track as LocalCameraTrack;
        await cameraTrack?.setDevice(options);
      } else {
        await track?.setDevice(options as string);
      }
    } catch {
      console.log(`Failed to change ${track.getTrackId()}:${track.getTrackLabel()} device`);
    }

    const newDeviceId = track?.getMediaStreamTrackSettings().deviceId;
    setDevice(newDeviceId);
  }, [track, isTrackReady]);

  useEffect(() => {
    if (!isTrackReady) {
      return;
    }

    setDevice(track.getMediaStreamTrackSettings().deviceId);
  }, [track, isTrackReady]);

  return {
    device,
    changeDevice
  }
}
